import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/userContext";

import {
  getProductData,
  InfoCompanyAfterPay,
} from "../services/companyServices";

import moment from "jalali-moment";

const Accounts = () => {
  const { allUsers, IsPayde } = useContext(UserContext);

  const [productData, setProductData] = useState([]);
  const [companyData, serCompanyData] = useState([]);

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const response = await getProductData();
        setProductData(response.data);
      } catch (error) {
        // console.log("no product found", error)
      }
    };
    fetchProductInfo();
  }, []);

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await InfoCompanyAfterPay();
        serCompanyData(response.data);
      } catch (error) {
        // console.log("no product found", error)
      }
    };
    fetchCompanyInfo();
  }, []);

  const convertToShamsi = (date) => {
    return moment(date, "YYYY-MM-DD").locale("fa").format("YYYY/MM/DD");
  };

  const calculateRemainingDays = (endDate) => {
    const today = moment();
    const end = moment(endDate, 'YYYY-MM-DD');
    return end.diff(today, 'days');
  };

  const remainingDays = companyData.Expire_Date
    ? calculateRemainingDays(companyData.Expire_Date)
    : null;

  return (
    <>
      <div
        className="flex flex-col overflow-y-auto w-full h-full"
      >
        {IsPayde === null ? (
          <div className="flex justify-end p-3">
            <p className="text-sm text-white text-start ">
              : اطلاعات حساب کاربری شما
            </p>
          </div>
        ) : (
          <div className="flex flex-col border bg-slate-100 rounded-xl justify-center items-center">
            <p className="text-stone-700 items-center text-lg m-2">Account</p>
            <div className="flex flex-col w-full">
              <span className="text-dark border-y-2 border-slate-400  items-center justify-center">
              {productData.length > 0 && (
                    <p className="text-center text-black text-lg mt-3">
                      اکانت یک ماه / تعداد کاربران {productData[0].NumberOfUser}
                    </p>
                )}
              </span>
              <div className="text-slate-500 m-1 text-center">
              {remainingDays !== null && (
                  <span className="text-lg text-center"> روز های باقی مانده {remainingDays} </span>
                )}
              </div>
            </div>

            </div>
        )}

        <div className="flex flex-col w-full mx-0 mt-3 text-black md:grid md:grid-cols-2  md:gap-5">
          <div className="flex flex-auto">
            <input
              className="w-full rounded-lg bg-slate-200 pt-2 mb-3 "
              disabled
              type="text"
              placeholder="تاریخ عضو شدن"
              value={
                companyData.Buy_Date
                  ? convertToShamsi(companyData.Buy_Date)
                  : ""
              }
            ></input>
          </div>
          <div className="flex flex-auto">
            <input
              className="w-full rounded-lg bg-slate-200 pt-2 mb-3 "
              disabled
              type="text"
              placeholder=" نام و نام خانوادگی"
              value={allUsers.full_name}

            ></input>{" "}
          </div>
          <div className="flex flex-auto">
            <input
              className="w-full rounded-lg bg-slate-200 pt-2 mb-3  "
              disabled
              type="text"
              placeholder="تاریخ انقضا اکانت"
              value={
                companyData.Expire_Date
                  ? convertToShamsi(companyData.Expire_Date)
                  : ""
              }

            ></input>{" "}
          </div>
          <div className="flex flex-auto">
            <input
              className="w-full rounded-lg bg-slate-200 pt-2 mb-3 "
              disabled
              type="text"
              placeholder="موجودی حساب"
              value={companyData.Charge_account}

            ></input>{" "}
          </div>
        </div>
      </div>{" "}
    </>
  );
};

export default Accounts;
