import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import './App.css';

import { SnackbarProvider, useSnackbar } from "notistack";

import { Toaster, toast } from 'react-hot-toast';


import { CompanyContext } from './context/companyContext';
import { adminContext } from './context/adminContext';
import { signupformContext } from './context/signupformContext';
import { UserContext } from './context/userContext';
import { ChatroomProvider } from './context/chatRoomContext';
import { ChatContext } from './context/chatGpt';


import { Changepass, Newpass, PassCode, createUser, GetUserInfo, GetIndividualUsers, LoginUser } from '../src/services/userServices'
import { createCompany, getCompanyData } from '../src/services/companyServices'
import { createAdmin, LogoutUser } from '../src/services/adminServices'
import { verify } from '../src/services/adminServices';


import EnterCode from './components/Entercode';
import PurchaseInfo from './components/PurchaseInfo';
import Notfound from './components/Notfound';

import Trying from './pages/Trying';
import SignupMain from './pages/SignupMain';
import LoginMain from './pages/LoginMain';
import Mainpage from './pages/Mainpage';
import { checkVerify } from './services/chatGpt';

function App() {

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false)


  const [NewPhone, SetNewPhone] = useState(false);

  const [ExistName, SetExistName] = useState(false);
  const [ExistEmial, SetExistEmial] = useState(false);

  const [mainToken, setmaintoken] = useState("");
  const [newToken, setNewToken] = useState("")



  const [admin, setAdmin] = useState({
    email: "",
    phone_number: "",
    full_name: "",
    password: "",
    confirmpassword: "",
    otp_code: ""
  })

  const [company, setCompany] = useState({
    full_name: "",
    Number_user: "",
    address: "",
  })

  const [user, setUser] = useState({
    phone_number: "",
    password: "",
    full_name: "",
    email: "",
    password2: ""
  })



  const [allUsers, setAllUsers] = useState({})
  const [Loginuser, setLoginUser] = useState({});
  const [checkLogin, setCheckLogin] = useState('');
  const [compUsers, setCompUsers] = useState({});

  const [chatRooms, setChatRooms] = useState([]);

  const [IsPayde, setIsPayed] = useState([]);

  const [IsAdmin, SetIsAdmin] = useState(false);
  const [incorrectLogin, setIncorrectLogin] = useState(false);


  //passwordchange
  const [currentphone, setCurrentphone] = useState('');
  const [newOtp, setNewOtp] = useState({})
  const [newpassword, setNewpassword] = useState({
    phone_number: "",
    new_password: "",
    confirm_password: ""
  });
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    const fetchcompany = async () => {
      try {
        const response = await getCompanyData();

        // console.log(response.data[0])
        setCompany(response.data[0]);
      } catch (error) {
        // console.log("Error fetching chats:", error);
      }
    };

    fetchcompany();
  }, []);

  ///for updating localstorage
  useEffect(() => {
    const phoneNumber = JSON.parse(localStorage.getItem("phone_number"));
    const full_name = JSON.parse(localStorage.getItem("full_name"));

    setAdmin((prevAdmin) => ({ ...prevAdmin, phone_number: phoneNumber }));
    setCompany((prevCompany) => ({ ...prevCompany, full_name: full_name }));
  }, [])

  const createForm = async (event) => {
    event.preventDefault();
    if (admin.password !== admin.confirmpassword) {
      setPasswordError('رمز عبور  یکسان نیست.');
      return;
    }
    try {
      const adminResponse = await createAdmin(admin)
      const companyResponse = await createCompany(company)
      if (adminResponse.status === 201 && companyResponse.status === 201) {
        localStorage.setItem("admin_registration_info", JSON.stringify(admin))
        localStorage.setItem("company_registration_info", JSON.stringify(company))
        window.sessionStorage.setItem("admin_registration_info", JSON.stringify(admin));
        window.sessionStorage.setItem("company_registration_info", JSON.stringify(company));
        navigate("/verify")
      }
    } catch (err) {
    }
    setPasswordError('');
  }

  const onInputChange = (event, setter) => {
    setter((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const triggerSignup = () => {
    toast.error(('شماره تکراری است. شماره جدید وارد کنید !'), {
      style: {
        background: 'red',
        color: 'white',
      },
    });
  }



  const createAdminForm = async (event) => {
    event.preventDefault();
    try {
      const response = await createAdmin(admin);
      if (response.status === 200) {
        localStorage.setItem("phone_number", JSON.stringify(admin.phone_number));
        SetNewPhone(true);
      } else {
        SetNewPhone(false);
      }
    } catch (err) {
      SetNewPhone(false);
      toast.error(('شماره تکراری است. شماره جدید وارد کنید !'), {
        style: {
          background: "red",
          color: "white"
        }
      });
    }
  };

  const verifyForm = async (event) => {
    event.preventDefault();
    try {
      const response = await verify(admin);
      if (response.status === 201) {
        localStorage.setItem("full_name", JSON.stringify(admin.full_name));
        setmaintoken(response.data.token)
        SetExistName(true);
        SetExistEmial(true);
      } else {
        SetExistName(false);
        SetExistEmial(false);
      }
    } catch (err) {
      toast.error(('این نام یا ایمیل قبلا ثبت شده است!'), {
        style: {
          background: "red",
          color: "white"
        }
      });
      SetExistName(false);
      SetExistEmial(false);
    }
  };


  const createCompanyForm = async (event) => {
    event.preventDefault();
    try {
      localStorage.setItem("token", mainToken);
      const response = await createCompany(company);
      console.log(response)
      if (response.status === 201) {
        setCheckLogin(true);
        window.location.href = '/chat';
      } else {
        console.log("name error ")
      }

    } catch (err) {
      toast.error(('.خطایی رخ داد.دوباره تلاش کنید'), {
        style: {
          background: "red",
          color: "white"
        }
      });
      setCheckLogin(false);

    }
  }

  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordColor, setPasswordColor] = useState('red');

  const checkPasswordStrength = (password) => {
    let strength = 'Weak'; 
    let color = 'red'; // Default color for weak passwords
  
    if (password.length >= 8) {
      if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).+$/.test(password)) {
        strength = 'Strong';
        color = 'green'; // Color for strong passwords
      } else {
        strength = 'Medium';
        color = 'orange'; // Color for medium strength passwords
      }
    }
  
    return { strength, color };
  };

  const onAdminChange = (e) => {
    const { name, value } = e.target;
    setAdmin((prevAdmin) => ({
      ...prevAdmin,
      [name]: value
    }));

    if (name === 'password' ) {
      const { strength, color } = checkPasswordStrength(value);
      setPasswordStrength(strength);
      setPasswordColor(color); // New state to hold color
    }
    
  };




  const onCompanyChange = (e) => {
    const { name, value } = e.target;
    if (name === 'full_name') {
      // Update the full_name property within the company state
      setCompany((prevCompany) => ({
        ...prevCompany,
        full_name: value
      }));
    } else {
      setCompany((prevCompany) => ({
        ...prevCompany,
        [name]: value
      }));
    }
  };



  const LoginForm = async (event) => {
    event.preventDefault();
    try {
      const response = await LoginUser(Loginuser.phone, Loginuser.password);
      if (response.status === 200) {
        // Once logged in, fetch user info
        const userInfoResponse = await GetUserInfo();
        setCheckLogin(true);
        setAllUsers(userInfoResponse);
        // window.location.href = '/chat'; // Directly navigate to '/chat'
      } else {
        setIncorrectLogin(true); // Set the flag to indicate incorrect login
      }
    } catch (err) {
      // Handle the error, if any
      toast.error(('.رمز عبور یا شماره اشتباه است'), {
        style: {
          background: "red",
          color: "white"
        }
      });
    }
  };


  const onUserChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const createUserForm = async (event) => {
    event.preventDefault();
    try {
      const { status } = await createUser(user);
      if (status === 201) {
        localStorage.setItem("user_registration_info", JSON.stringify(user))
        window.sessionStorage.setItem("user_registration_info", JSON.stringify(user));
        setUser({
          full_name: '',
          phone_number: '',
          email: '',
          password: '',
          password2: '',
        });
      }

    } catch (err) {
      toast.error(('.خطایی رخ داد.دوباره تلاش کنید'), {
        style: {
          background: "red",
          color: "white"
        }
      });
    }
  }


  const [forgetSuccess, setForgetSuccess] = useState('')

  const forgetpass = async (event) => {
    event.preventDefault();
    Changepass({ phone_number: currentphone })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("currentphone", JSON.stringify(currentphone));
          setForgetSuccess(true);
        } else {
          // Handle other response statuses if needed
        }
      })
      .catch((error) => {
        toast.error('خطایی رخ داد. دوباره تلاش کنید', {
          style: {
            background: "red",
            color: "white"
          }
        });
      });
  };

  const [verifyOtp, setVerifyOtp] = useState('')

  const VerifyNewOtp = async (event) => {
    event.preventDefault();

    PassCode({ phone_number: currentphone, otp_code: newOtp })
      .then((response) => {
        const token = response.data.token
        setNewToken(token)
        setVerifyOtp(true)
      })
      .catch((err) => {
        toast.error(('.خطایی رخ داد.دوباره تلاش کنید'), {
          style: {
            background: "red",
            color: "white"
          }
        });
      });
  };





  const NewpassConfirm = async (event) => {
    event.preventDefault();
    try {
      const storedToken = newToken; // Retrieve the newly stored token
      console.log(storedToken)
      const response = await Newpass({
        phone_number: currentphone,
        new_password: newpassword.new_password,
        confirm_password: newpassword.confirm_password,
        token: storedToken // Pass the stored token to the Newpass function
      });
      console.log(response)
      if (response.status === 200) {
        toast.success('!رمز شما با موفقیت عوض شد 🎉');
        window.location.href = '/';
      } else {
        toast.error('.دوباره تلاش کنید');
      }
    } catch (err) {
      toast.error('.خطایی رخ داد. دوباره تلاش کنید');

    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setNewpassword((prevPassword) => ({
      ...prevPassword,
      [name]: value
    }));
    if (name === 'new_password') {
      const { strength, color } = checkPasswordStrength(value);
      setPasswordStrength(strength);
      setPasswordColor(color);
    }
  };

  const LogoutForm = async () => {
    try {
      const token = localStorage.getItem('token');
      // Set loading state to true
      setLoading(true);

      const { status } = await LogoutUser(token);

      if (status === 200) {
        localStorage.removeItem('token');
        navigate('/');
      }
    } catch (err) {
      // Handle any errors here
      toast.error(('.خطایی رخ داد.دوباره تلاش کنید'), {
        style: {
          background: "red",
          color: "white"
        }
      });
    } finally {
      // Set loading state to false
      setLoading(false);
      toast.error(('.خطایی رخ داد.دوباره تلاش کنید'), {
        style: {
          background: "red",
          color: "white"
        }
      });
    }
  };
  
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await GetUserInfo();
        setAllUsers(response.data);
      } catch (error) {
      }
    };

    fetchUserInfo();
  }, [setAllUsers]);



  useEffect(() => {
    const fetchIndividual = async () => {
      try {
        const response = await GetIndividualUsers();
        // console.log(response)
        if (response && response.length > 0) {

          // Set the user data array to the state variable (assuming setCompUsers is a function to set state)
          setCompUsers(response);
          SetIsAdmin(true)
        } else {
        }
      } catch (error) {
      }
    };

    fetchIndividual();
  }, []);
  useEffect(() => {
    const checkVerifyy = async () => {
      try {
        const response = await checkVerify();
        if (response && response.data && response.data.last_paid_id !== null) {
          const lastPaidId = response.data.last_paid_id;

          // console.log("Last Paid ID:", lastPaidId);
          setIsPayed(lastPaidId);
        } else {
          // console.log("No last paid ID found in the response data");
        }
      } catch (error) {
        // console.log("no user found", error);
        setIsPayed(null)
      }
    };
    checkVerifyy();
  }, []);



  return (
    <signupformContext.Provider value={{
      onInputChange,
      createForm,
      LogoutForm,
      triggerSignup
    }}>
      <adminContext.Provider value={{
        admin,
        setAdmin,
        IsAdmin,
        SetIsAdmin,
        onAdminChange,
        verifyForm,
        createAdminForm,
        loading,
        setLoading,
        passwordColor

      }}>
        <CompanyContext.Provider value={{
          company,
          setCompany,
          onCompanyChange,
          createCompanyForm

        }}>
          <UserContext.Provider value={{
            user,
            allUsers,
            setUser,
            setAllUsers,
            Loginuser,
            setLoginUser,
            checkLogin,
            forgetSuccess,
            setForgetSuccess,
            verifyOtp,
            setVerifyOtp,
            compUsers,
            setCompUsers,
            onUserChange,
            LoginForm,
            createUserForm,
            forgetpass,
            currentphone,
            setCurrentphone,
            VerifyNewOtp,
            newOtp,
            setNewOtp,
            NewpassConfirm,
            setNewpassword,
            handleFormChange,
            GetUserInfo,
            IsPayde,
            setIsPayed,

          }} >
            <ChatContext.Provider value={{
              chatRooms,
              setChatRooms
            }}>
              <ChatroomProvider>
                <Routes>
                  <Route path='/' element={token ? <Navigate to="/chat" /> :
                    <Trying passwordError={passwordError} NewPhone={NewPhone} ExistName={ExistName}
                      ExistEmial={ExistEmial} incorrectLogin={incorrectLogin} setIncorrectLogin={setIncorrectLogin}
                    />} />
                   {/* <Route path='/' element={<Trying passwordError={passwordError} NewPhone={NewPhone} ExistName={ExistName}
                   ExistEmial={ExistEmial} incorrectLogin={incorrectLogin} setIncorrectLogin={setIncorrectLogin}
                 />} /> */}
                 

                  <Route path='/chat' element={token ? <Mainpage /> : <Notfound />} />

                  <Route path='/purchaseInfo' element={token ? <PurchaseInfo /> : <Notfound />} />
                </Routes>
              </ChatroomProvider>
            </ChatContext.Provider>


          </UserContext.Provider>
        </CompanyContext.Provider>
      </adminContext.Provider>
    </signupformContext.Provider>

  );
}

export default App;
