import axios from 'axios'
import Cookies from 'js-cookie';


const SERVER_URL = "https://chat.ivaai.ir";

const csrftoken = Cookies.get('csrftoken');

// const token = localStorage.getItem("token");

export const createUser = (user) => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  };

  const url = `${SERVER_URL}/registerEmployee/`;
  return axios.post(url, user, { headers });
};


export const getAllUsers = () => {
  const url = `${SERVER_URL}/users`;
  return axios.get(url);
};

export const LoginUser = async (phone, password) => {
  const url = `${SERVER_URL}/login/`;
  const response = await axios.post(url, { phone, password });
  const token = response.data.token;
  // storing it in local storage 
  localStorage.setItem("token", token);
  return token;
};

// accessing all users
export const GetUserInfo = async () => {
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,

  };
  const url = `${SERVER_URL}/getuserinfo/`;
  const response =await axios.get(url, { headers });
  return response;
};

export const GetIndividualUsers = async () => {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const url = `${SERVER_URL}/getusercompany/`;
  const response = await axios.get(url, { headers });
  // console.log(response.data)
  return response.data; // Return the data property of the response
};


////forget password 
export const Changepass = (phone_number) => {
  const url = `${SERVER_URL}/s/`;
  const response= axios.post(url, phone_number);
  return response
};

export const PassCode = async (phone_number, otp_code) => {
  const url = `${SERVER_URL}/v/`;
  const response = await axios.post(url, phone_number, otp_code);

  return response;
};

export const Newpass = async ({ phone_number,
  new_password,
  confirm_password,
  token
}) => {
  // localStorage.setItem("token", JSON.stringify(token));

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };
  console.log("new token is ", token)

  const url = `${SERVER_URL}/c/`;
  const response = await axios.post(url, { phone_number, new_password, confirm_password }, { headers });
  // console.log(response);
  return response
};


