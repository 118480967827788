import React, { useEffect, useState } from "react";

const ChatRoomComponent = ({ chatRoom, onChildClick, isClicked }) => {


  const firstMessage = chatRoom.chats.length > 0 ? chatRoom.chats[0] : null;
  const handleClick = () => {
    onChildClick(chatRoom.id);
  };

  const cleanTextToSend = firstMessage ? firstMessage.text_to_send.replace( /\\n/g, " ").replace(/^"(.*)"$/, '$1') : null;
  // let displayedText = cleanTextToSend
  //                             .replace(/\\n/g, " ") // Convert literal "\n" characters (escaped) to <br />
    
  return (
    <div
      className={`relative mx-2 items-center justify-center overflow-hidden
      ${isClicked ? "clicked" : ""}`}
      style={{
        height: "4vh",
        backgroundColor: isClicked ? "inherit" : "inherit",
        direction:"ltr",
        boxShadow:"none"
      }}
      onClick={handleClick}
    >
      {cleanTextToSend && (
        <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" ,textAlign:"start" , alignItems:"center"}}
         className="text-capitalize text-white text-left w-full mt-1">
          {cleanTextToSend}
        </p>
      )}
    </div>
  );
};

export default ChatRoomComponent;
