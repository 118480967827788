
import { Icon } from "@iconify/react";

let logo = require("../assets/favicon.ico.png");

const Header = () => {
  return (
    <div className="container-fluid">
    <div className="header ">
      <div className="row w-100 border border-black d-flex justify-content-between">
        <div className="col-lg-4 col-md-3 col-sm-2 text-start">
          {" "}
          <Icon icon="mingcute:menu-fill"  height="6vh" className="menu  border-black " />
        </div>
        <div className="col-lg-4 col-md-3 col-sm-2 d-flex justify-content-end">
          <img className="w-25" src={logo} />
        </div>
      </div>
    </div>
  </div>
  );
};

export default Header;
