import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  addToCart,
  getOrderId,
  getOrderIdBehpardakht,
} from "../services/chatGpt";

import "../css/shopbtn.css";

let zarinpal = require("../assets/zarinpal.png");
let behpardakht = require("../assets/behpardakht.png");

const Shopbtn = ({ productId }) => {
  const [order_Id, setOrder_Id] = useState([]);

  const navigate = useNavigate(); // useNavigate hook to

  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);

  const toggleAdditionalButtons = () => {
    setShowAdditionalButtons(!showAdditionalButtons);
  };


  const AddtoCartFunction = async (productId, paymentMethod) => {
    try {
      const response = await addToCart(productId);
      const orderId = response.data.order_id;

      if (paymentMethod === "zarinpal") {
        handleOrderDetail(orderId);
      } else if (paymentMethod === "behpardakht") {
        handleOrderDetail2(orderId);
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };


  const handleOrderDetail = (orderId) => {
    if (orderId) {
      getOrderId(orderId)
        .then(async (response) => {
          if (response.data && response.data.redirect_url) {
            window.location.href = response.data.redirect_url;
          } else {
            navigate("/chat");
          }
        })
        .catch((error) => {
          navigate("/chat");
        });
    }
  };

  const handleOrderDetail2 = (orderId) => {
    if (orderId) {
      getOrderIdBehpardakht(orderId)
        .then(async (response) => {
          console.log("Response Data:", response);
  
          if ( response.data ) {
            window.location.href = response.data;
          } else {
            navigate("/chat");
          }
        })
        .catch((error) => {
          navigate("/chat");
        });
    }
  };


  return (
    <>
      <div
        className="button w-100"
        id="mainButton"
        onClick={toggleAdditionalButtons}
      >
        <div className="button-wrapper ">
          <div className="text">خرید</div>
          <span className="icon">
            <svg
              viewBox="0 0 16 16"
              class="bi bi-cart2"
              fill="currentColor"
              height="16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"></path>
            </svg>
          </span>
        </div>
      </div>
      <div
        class="pay-option row p-3 gap-4"
        id="additionalButtons"
        style={{ display: showAdditionalButtons ? "flex" : "none" }}
      >
        <button
          onClick={() => AddtoCartFunction(productId, "zarinpal")}
          class="col rounded-2 d-flex justify-content-center align-items-center"
        >
          <img
            id="zarinpal"
            src={zarinpal}
            alt="zarinpal"
            style={{ height: "5em" }}
          />{" "}
        </button>
        <button
          onClick={() => AddtoCartFunction(productId, "behpardakht")}
          class="col rounded-2 "
        >
          <img
            id="behpardakht"
            src={behpardakht}
            alt="behpardakht"
            style={{ height: "5em" }}
          />{" "}
        </button>
      </div>
    </>
  );
};

export default Shopbtn;
