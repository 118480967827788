import { useContext } from "react";
import * as React from "react";

import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import hljs from "highlight.js";

import { franc } from "franc-min";

import Spinner from "../components/Spinner";

import {
  SendChat,
  AllchatRooms,
  newChat,
  generatorImg,
  SendChat4,
  DallE,
  Chat4o,
  getImageGenerator,
} from "../services/chatGpt";
import { GetUserInfo } from "../services/userServices";
import { UserContext } from "../context/userContext";

import "../css/mainchat.css";
import "../css/dracula.css";

import Insidenav from "../components/Insidenav";

import Secondsidenav from "../components/Secondsidenav";
import Typewriter from "../components/Typewriter";
import Tooltip from "@mui/material/Tooltip";
import TypewriterRecieved from "../components/TypewriterRecieved";

let logo = require("../assets/favicon.ico.png");
let profile = require("../assets/profilee.jpg");

const Mainpage = () => {
  const chatContainerRef = useRef(null);
  const navigate = useNavigate();

  const { IsPayde, setAllUsers } = useContext(UserContext);

  const [inputActive, setInputActive] = useState(false);

  // for the payment alert
  const [open, setOpen] = React.useState(true);

  const [loading, setloading] = useState(false);

  const [processingChat, setProcessingChat] = useState(false);

  const [isNewChatClicked, setIsNewChatClicked] = useState(false);
  const [disabledTextarea, setDisabledTextarea] = useState(false);

  // State variables to store text and code separately
  const [textMessages, setTextMessages] = useState([]);
  const [codeMessages, setCodeMessages] = useState([]);

  //sendinput function activated or not
  const [showChatroomHis, setShowChatroomHis] = useState(false);

  const [textToSend, setTextToSend] = useState("");
  const [failedResponse, setFailedResponse] = useState(true);

  const [currentChatroom, setCurrentChatroom] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);

  const [chatResponse, setchatResponse] = useState([]);
  //chatrooms chats history
  const [chatHisMess, setChatHisMess] = useState([]);
  const [chatHisResponse, setChatHisResponse] = useState([]);

  //chatrooms
  const [chatRooms, setChatRooms] = useState([]);

  const [chatMessagesPrev, setChatMessagesPrev] = useState([]);

  const [updatedChatMessages, setUpdatedChatMessages] = useState([]);
  //last clicked chatroom
  const [lastClickedChatRoomId, setLastClickedChatRoomId] = useState(null);

  //keep track of clicked component in the chatroomcomponent
  const [clickedComponent, setClickedComponent] = useState(false);

  //chatrooms chats
  const [allChatData, setAllChatData] = useState([]);

  const handleChildClick = (chatRoomId) => {
    setClickedComponent(true);
    setLastClickedChatRoomId(chatRoomId);
    setDisabledTextarea(true);
    console.log("??")
  };

  // Save the last clicked chatroom ID in local storage
  useEffect(() => {
    localStorage.setItem("lastClickedChatRoomId", lastClickedChatRoomId);
    console.log("clicked")

  }, [lastClickedChatRoomId]);

  // Retrieve the last clicked chatroom ID when component mounts
  useEffect(() => {
    const lastClickedId = localStorage.getItem("lastClickedChatRoomId");
    console.log("clicked")

    if (lastClickedId) {
      setLastClickedChatRoomId(lastClickedId);
      console.log("clicked")
    }
  }, []);

  const handleAllChatDataUpdate = (updatedData, clickedChatRoomId) => {
    const newChatMess = updatedData.map((chatItem) => chatItem.text_to_send);
    const newChatResponse = updatedData.map(
      (chatItem) => chatItem.text_to_receive
    );

    setChatHisMess(newChatMess);
    setChatHisResponse(newChatResponse);

    // Update the chat messages received from ChatroomHis in the parent component
    setUpdatedChatMessages(updatedData);
  };

  const onChatChange = (event) => {
    const text = event.target.value;
    setTextToSend(text);
  };

  // Scroll to bottom function
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      // Wait for a very short delay before scrolling to the bottom
      setTimeout(() => {
        chatContainerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100); // Example delay of 100 milliseconds
    }
  };

  const [selectedService, setSelectedService] = useState([]);

  const handleServiceSelect = (event) => {
    setSelectedService(event.target.value);
  };

  const isPersian = (text) => {
    // Regex to match Persian characters
    const persianCharRegex = /[\u0600-\u06FF\uFB8A\u067E\u0686\u06AF\u200C]+/;
    return persianCharRegex.test(text);
  };

  const formatMessageForStorage = (message) => {
    // Replace newline characters with a special token (e.g., "\n" becomes "<br/>")
    return message.replace(/\n/g, "<br/>").trim(); // Use .trim() to remove leading/trailing spaces
  };

  const sendChatInput = async () => {
    inputClear();
    setTextToSend(" ");

    // Disabling input during processing
    setProcessingChat(true);
    const formattedMessage = formatMessageForStorage(textToSend)
      .replace(/\n/g, "<br/>") // Replace newlines with <br/>
      .replace(/ /g, "&nbsp;"); // Replace spaces with &nbsp;
    // Add the sent message to the chat messages
    setChatMessages((prevMessages) => [...prevMessages, formattedMessage]);
    console.log(formattedMessage);

    try {
      setloading(true);
      scrollToBottom();

      let responseData, imgUrl, imgUrl1, DalleImg;

      // this works
      if (selectedService === "generatorImg") {
        imgUrl1 = await generatorImg(textToSend);
        imgUrl = await getImageGenerator();
      } else if (selectedService === "dalle") {
        DalleImg = await DallE(textToSend);
      } else if (selectedService === "IVAPRO") {
        responseData = await SendChat4(textToSend);
      } else if (selectedService === "chatgpt4o") {
        responseData = await Chat4o(textToSend);
      } else {
        responseData = await SendChat(textToSend);
      }

      let response;

      if (imgUrl) {
        response = imgUrl; // If imgUrl is defined, use its response
      } else if (DalleImg) {
        response = DalleImg; // If DalleImg is defined, use it as the response
      } else {
        response = responseData?.data?.response; // Otherwise, use the response from responseData
      }

      // Check if the response is a URL
      const isUrl = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/i.test(response);

      if (isUrl) {
        setchatResponse((prevResponses) => [
          ...prevResponses,
          { image_to_receive: response, image_to_receive_URL: response },
        ]);
      } else {
        setchatResponse((prevResponses) => [
          ...prevResponses,
          response || "خطایی رخ داد . دوباره تلاش کنید .",
        ]);
      }

      setloading(false);
      setShowChatroomHis(true);

      if (!currentChatroom) {
        setCurrentChatroom(chatRooms[0]);
      }
      const languageCode = franc(response);

      const isPersian = (text) => {
        const persianCharRegex = /[\u0600-\u06FF]/;
        return persianCharRegex.test(text);
      };
      const direction = isPersian(response) ? "rtl" : "ltr";

      const updatedData = [
        ...updatedChatMessages,
        {
          text_to_send: formattedMessage,
          text_to_receive: isUrl ? null : response,
          image_to_receive_URL: isUrl ? response : null,
          direction: direction,
        },
      ];

      setUpdatedChatMessages(updatedData);
      scrollToBottom();
    } catch (err) {
      setloading(false);
      setFailedResponse(true);
      setchatResponse((prevResponses) => [
        ...prevResponses,
        "خطایی رخ داد . دوباره تلاش کنید . ",
      ]);
    } finally {
      // Enable input after processing is done
      setProcessingChat(false);
    }
  };

  useEffect(() => {}, [textToSend]);

  const inputClear = () => {};

  // Function to update chat messages in the parent component
  const updateChatMessages = (messages) => {
    setChatMessagesPrev(messages);
  };

  //chats returning from child
  const [chatRoomsData, setChatRoomsData] = useState([]);
  // Your logic to fetch and process chat data

  const saveChatData = (chatData) => {
    setChatRoomsData(chatData);
  };

  useEffect(() => {}, [chatRoomsData]);

  const handleNewChat = () => {
    setChatMessages([]);
    setchatResponse([]);
    setChatHisMess([]);
    setChatHisResponse([]);
    setClickedComponent(false);
    setInputActive(true);
    setDisabledTextarea(false)
    // setIsNewChatClicked(true); // Set this to true when new chat is clicked
  };

  // Function to fetch chat rooms again whenever new room is created
  const handleNewChatRoom = async () => {
    try {
      const response = await AllchatRooms();
      if (Array.isArray(response)) {
        const formattedChatRooms = response.map((chatRoom) => {
          return chatRoom;
        });
        setChatRooms(formattedChatRooms);
      } else {
        // console.log("Invalid response format");
      }
    } catch (error) {
      // console.log("Error fetching chats:", error);
    }
  };

  useEffect(() => {
    // Check if the chatRooms array is empty or can have additional checks based on the requirement
    if (chatRooms.length === 0) {
      handleNewChatRoom();
    }
  }, [chatRooms]);

  const chatButton = () => {
    newChat();
    handleNewChatRoom();
    handleNewChat();
  };

  // make a new chat when entered or page refreshed
  useEffect(() => {
    newChat();
    handleNewChatRoom();
    handleNewChat();
    setDisabledTextarea(false)

  }, []);

  window.onload = function () {
    // Set the initial width of the navbar to 0
    document.getElementById("mySidenav").style.width = "0";
  };

  // for the sidenav for phones
  const openNav = () => {
    const mySidenav = document.getElementById("mySidenav");
    mySidenav.style.width = "100%";
  };

  const copyFunction = (elementIds) => {
    elementIds.forEach((id) => {
      const messageElement = document.getElementById(id);
      const textToCopy = messageElement.innerText.trim();

      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;

      document.body.appendChild(textArea);

      textArea.select();
      document.execCommand("copy");

      document.body.removeChild(textArea);
    });
  };

  // textarea
  const textareaRef = useRef(null);

  // Adjust the height of the textarea
  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto"; // Reset the height
    textarea.style.height = `${textarea.scrollHeight}px`; // Set it to the scroll height
  };

  useEffect(() => {
    adjustTextareaHeight(); // Initial adjustment
  }, [textToSend]);

  // fetches users data when in the page
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await GetUserInfo();
        // console.log(response)
        setAllUsers(response.data);
      } catch (error) {}
    };

    fetchUserInfo();
  }, [setAllUsers]);

  return (
    <>
      <div className="flex flex-col w-screen h-screen ">
        <div className="flex flex-row  w-full justify-end md:hidden ">
          <div className="w-full flex flex-row justify-between items-center mx-3 mt-2">
            <div>
              <iconify-icon
                icon="octicon:feed-plus-16"
                width="2em"
                style={{ color: "white" }}
                onClick={chatButton}
              ></iconify-icon>
            </div>
            <div>
              {" "}
              <iconify-icon
                icon="typcn:th-menu"
                width="2.1em"
                id="menuicon"
                style={{ color: "white", cursor: "pointer" }}
                onClick={openNav}
              ></iconify-icon>{" "}
            </div>
          </div>
        </div>
        <Insidenav
          updateChatMessages={updateChatMessages}
          saveChatData={saveChatData}
          allChatData={allChatData}
          onAllChatDataUpdate={handleAllChatDataUpdate}
          onClick={handleChildClick}
          handleNewChat={handleNewChat}
          lastClickedChatRoomId={lastClickedChatRoomId}
        />
        <div className="flex flex-col justify-center items-center h-8 md:flex md:flex-row md:top-0 md:bg-bgDark md:justify-start rounded-tr-lg rounded-tl-lg">
          <img src={logo} className="navlogo hidden md:block " width="50em" />

          <p className="text-white font-bold justify-center md:inline-block md:font-light md:mt-5">
            THE IVA
          </p>
        </div>

        <div className="flex flex-row md:hidden">
          <select
            className="form-select m-2 mx-3  h-8 font-medium"
            aria-label="Default select "
            id="select_Servic"
            onChange={handleServiceSelect}
          >
            <option selected value="IVA">
              {" "}
              ChatGpt 3.5{" "}
            </option>
            <option value="IVAPRO">ChatGpt 4 </option>
            <option value="chatgpt4o">ChatGpt 4o </option>
            <option value="dalle">DALL-E 3 </option>

            <option value="generatorImg">Img generate</option>
          </select>
        </div>
        {/* chatpart // md part  */}
        <div className="flex flex-col md:flex-row  h-screen w-full relative overflow-hidden mb-2 rounded-br-lg rounded-bl-lg bg-bgDark">
          <div className="hidden md:flex md:flex-row md:w-1/5 md:relative md:text-white md:border-r  md:mr-2">
            <div className="flex flex-col w-full">
              <Secondsidenav
                updateChatMessages={updateChatMessages}
                saveChatData={saveChatData}
                allChatData={allChatData}
                onAllChatDataUpdate={handleAllChatDataUpdate}
                onClick={handleChildClick}
                handleNewChat={handleNewChat}
                lastClickedChatRoomId={lastClickedChatRoomId}
              />
            </div>{" "}
          </div>
          <div className=" h-full flex-row w-full overflow-y-auto md:overflow-hidden scroll-smooth   relative">
            <div className=" hidden md:block  md:bg-bgDark md:w-full md:absolute md:z-10 ">
              {" "}
              <div className=" flex-row  ml-3 md:mt-1 md:w-1/4 ">
                <select
                  className="form-select my-2 h-8 font-medium border border-slate-400 focus:border-slate-300"
                  aria-label="Default select "
                  id="select_Servic"
                  onChange={handleServiceSelect}
                >
                  <option selected value="IVA">
                    {" "}
                    ChatGpt 3.5{" "}
                  </option>
                  <option value="IVAPRO">ChatGpt 4 </option>
                  <option value="chatgpt4o">ChatGpt 4o </option>
                  <option value="dalle">DALL-E 3 </option>

                  <option value="generatorImg">Img generate</option>
                </select>
              </div>
            </div>

            {/* middle part */}
            <div className="chatspart  w-full md:pt-14 md:pb-16 md:relative md:flex md:flex-col md:h-full ">
              <div className="mainchat h-full w-full  justify-center p-2 md:max-h-full md:flex-row md:overflow-y-auto ">
                {/* chat message dispaly */}
                <div className="pb-5 ">
                  <div className="flex flex-col relative w-full rounded-xl mt-3 ">
                    {clickedComponent ? (
                      <>
                        {/* retrieved messages */}
                        {updatedChatMessages &&
                          updatedChatMessages.length > 0 &&
                          updatedChatMessages.map((chatItem, index) => {
                            const SENT_MESSAGE_ID = `sendChat-${index}`;
                            const RECEIVED_MESSAGE_ID = `receivedChat-${index}`;


                            let textToSend = chatItem.text_to_send; 
                            let displayedText = textToSend
                              .replace(/^"|"$/g, "") // Remove leading and trailing double quotes
                              .replace(/\\n/g, "<br />") // Convert literal "\n" characters (escaped) to <br />
                              .replace(/\s+/g, " ") // Replace multiple spaces with a single space
                              .trim(); // Trim whitespace from the beginning and end

                            console.log(displayedText);

                            return (
                              <React.Fragment key={index}>
                                <div
                                  className="flex flex-col rounded-xl mt-2 mb-2 bg-gray-500 mx-5  min-h-32 "
                                  id={SENT_MESSAGE_ID}
                                  
                                  // id="sendChat"
                                >
                                  <div className="flex flex-row justify-between w-full h-10 md:h-12 p-2 mt-2 ">
                                    <span className="flex flex-row ml-2">
                                      <img
                                        className=" rounded-full w-6 md:h-8 md:w-8 ml-2"
                                        src={profile}
                                      />
                                    </span>
                                    <div
                                      onClick={() =>
                                        copyFunction([SENT_MESSAGE_ID])
                                      }
                                    >
                                      <Tooltip title="COPY" placement="top">
                                        <iconify-icon
                                          icon="solar:copy-outline"
                                          width="1.2rem"
                                          height="1.2rem"
                                          style={{ color: "white" }}
                                        ></iconify-icon>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className=" mx-2 mt-2">
                                    <p
                                      className="text-white w-full h-full flex-wrap font-medium leading-relaxed mx-1.5"
                                      style={{
                                        direction: isPersian(chatItem)
                                          ? "rtl"
                                          : "ltr",
                                          lineHeight: "2",
                                      }}
                                    >
                                      <span className='text-base'
                                     
                                        dangerouslySetInnerHTML={{
                                          __html: displayedText, // Convert newlines to <br />
                                        }}
                                      ></span>
                                    </p>
                                  </div>
                                </div>



                                <div
                                  className="flex flex-col rounded-xl mb-3 mt-4 bg-gray-700 mx-5  min-h-32 "
                                  id={RECEIVED_MESSAGE_ID}
                                  // id="recievedChat"
                                >
                                  <div className="flex flex-row justify-between w-full h-10 md:h-12 p-2 mt-2 ">
                                    <span className="flex flex-row ">
                                      {" "}
                                      <img
                                        className="rounded-full w-8 md:h-8 md:w-10 h-full ml-2 "
                                        src={logo}
                                      />
                                      <span className=" justify-center items-center py-1 text-white text-base mt-1">
                                        The IVA
                                      </span>
                                    </span>
                                    <div
                                      onClick={() =>
                                        copyFunction([RECEIVED_MESSAGE_ID])
                                      }
                                    >
                                      <Tooltip title="COPY" placement="top">
                                        <iconify-icon
                                          icon="solar:copy-outline"
                                          width="1.2rem"
                                          height="1.2rem"
                                          style={{ color: "white" }}
                                        ></iconify-icon>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  {chatItem.image_to_recive_URL ? (
                                    <div className="d:grid md:grid-cols-2 md:w-2/3 p-3 rounded-md ">
                                      <img
                                        className="received-image w-50"
                                        src={chatItem.image_to_recive_URL}
                                        alt="Received Image"
                                      />
                                    </div>
                                  ) : (
                                    <div className="text-white w-full h-full font-medium leading-relaxed md:pt-3 md:text-xl">
                                      <TypewriterRecieved
                                        text={chatItem.text_to_recive}
                                      />
                                    </div>
                                  )}

                                  <span>{chatItem.timestamp}</span>
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </>
                    ) : (
                      <>
                        {/* concurrent sent messages */}
                        {chatMessages.map((userMessage, index) => {
                          const currentResponse = chatResponse[index];

                          const SENT_MESSAGE_ID = `sendChat-${index}`;
                          const RECEIVED_MESSAGE_ID = `receivedChat-${index}`;

                          return (
                            <div key={index}>
                              <div
                                id={SENT_MESSAGE_ID}
                                // id="sendChat"
                                className="rounded-xl mt-2 mb-2 bg-gray-500 mx-5  min-h-32 "
                              >
                                {/* new */}
                                <div className="flex flex-col h-auto min-h-32 w-full rounded-xl">
                                  <div className="flex flex-row justify-between w-full h-10 md:h-12 p-2 mt-2 relative">
                                    <span className="flex flex-row ml-2">
                                      <img
                                        className=" rounded-full w-6 md:h-8 md:w-8 ml-2"
                                        src={profile}
                                      />
                                    </span>

                                    <div
                                      onClick={() =>
                                        copyFunction([SENT_MESSAGE_ID])
                                      }
                                    >
                                      <Tooltip title="COPY" placement="top">
                                        <iconify-icon
                                          icon="solar:copy-outline"
                                          width="1.2rem"
                                          height="1.2rem"
                                          style={{ color: "white" }}
                                        ></iconify-icon>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className=" mx-2 relative ">
                                    <p
                                      className="text-white w-full h-full p-2 font-medium leading-relaxed overflow-y-auto md:text-lg mx-2"
                                      style={{
                                        direction: isPersian(userMessage)
                                          ? "rtl"
                                          : "ltr",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: userMessage,
                                      }}
                                    >
                                      {/* {userMessage.replace(/^\"(.*)\"$/, "$1")} */}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              {index === chatMessages.length - 1 && loading ? (
                                <div className="message chat-response p-1 ms-5 mt-4 mb-2 ">
                                  <Spinner />
                                </div>
                              ) : (
                                <>
                                  {/* response */}
                                  {typeof currentResponse === "object" ? (
                                    <div id={RECEIVED_MESSAGE_ID}>
                                      {currentResponse.image_to_receive_URL &&
                                      currentResponse.image_to_receive_URL.startsWith(
                                        "https://"
                                      ) ? (
                                        <>
                                          <div className=" flex flex-col md:flex-row justify-between mx-5 rounded-xl bg-[#282C34]">
                                            <img
                                              className="md:grid md:grid-cols-2 md:w-[50%] p-3 rounded-md"
                                              src={
                                                currentResponse.image_to_receive_URL
                                              }
                                              alt="Received Image"
                                            />
                                            <div className=" mx-0 flex justify-end download-button ">
                                              <a
                                                className="md:p-1 md:m-2"
                                                href={
                                                  currentResponse.image_to_receive_URL
                                                }
                                                download="received_image.jpg"
                                              >
                                                <iconify-icon
                                                  icon="bi:download"
                                                  width="1.5em"
                                                  height="1.5em"
                                                  style={{
                                                    color: "white",
                                                    cursor: "pointer",
                                                  }}
                                                ></iconify-icon>
                                              </a>
                                            </div>
                                          </div>
                                          {currentResponse.text_to_receive && (
                                            <div
                                              className="text-white m-4 md:text-xl "
                                              style={{
                                                direction: /[A-Za-z]/.test(
                                                  currentResponse
                                                )
                                                  ? "ltr"
                                                  : "rtl",
                                              }}
                                            >
                                              {currentResponse.text_to_receive
                                                .split("\\n")
                                                .map((paragraph, i) => (
                                                  <p
                                                    key={i}
                                                    className="text-white p-1 md:text-xl"
                                                  >
                                                    {paragraph}
                                                  </p>
                                                ))}
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <div
                                          className="text-white m-4 md:text-lg  "
                                          style={{
                                            direction: isPersian(
                                              currentResponse
                                            )
                                              ? "rtl"
                                              : "ltr",
                                          }}
                                        >
                                          {currentResponse.text_to_receive ? (
                                            currentResponse.text_to_receive
                                              .split("\\n")
                                              .map((paragraph, i) => (
                                                <p
                                                  key={i}
                                                  className="text-white p-1 md:text-lg"
                                                >
                                                  {paragraph}
                                                </p>
                                              ))
                                          ) : (
                                            <div className="flex justify-end">
                                              <p className="text-end md:text-lg">
                                                "خطایی رخ داد . دوباره تلاش کنید
                                                "
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      id={RECEIVED_MESSAGE_ID}
                                      className="mx-5 "
                                    >
                                      {/* new */}
                                      <div className="flex flex-col relative w-full rounded-xl mb-3 mt-4 bg-[#282C34]">
                                        <div className="flex flex-col  h-auto min-h-32 w-ful">
                                          <div className="flex flex-row justify-between w-full h-10 md:h-12 p-2 mt-2 ">
                                            <span className="flex flex-row ml-2">
                                              {" "}
                                              <img
                                                className="rounded-full w-8 md:h-8 md:w-10  h-full ml-2"
                                                src={logo}
                                              />
                                              <span className=" justify-center items-center py-1 text-white text-base mt-1">
                                                The IVA
                                              </span>
                                            </span>
                                            <div
                                              onClick={() =>
                                                copyFunction([
                                                  SENT_MESSAGE_ID,
                                                  RECEIVED_MESSAGE_ID,
                                                ])
                                              }
                                            >
                                              <Tooltip
                                                title="COPY"
                                                placement="top"
                                              >
                                                <iconify-icon
                                                  icon="solar:copy-outline"
                                                  width="1.2rem"
                                                  height="1.2rem"
                                                  style={{ color: "white" }}
                                                ></iconify-icon>
                                              </Tooltip>
                                            </div>
                                          </div>
                                          <div className=" mx-2 relative overflow-auto">
                                            <p
                                              className="text-white w-full h-full  leading-relaxed md:text-xl"
                                              style={{
                                                direction: isPersian(
                                                  currentResponse
                                                )
                                                  ? "rtl"
                                                  : "ltr",
                                              }}
                                            >
                                              <Typewriter
                                                text={currentResponse}
                                                delay={900}
                                              />{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div ref={chatContainerRef} />
                </div>
              </div>

              <div className="w-full m-auto fixed flex z-2 bottom-0 md:absolute  overflow-hidden justify-center">
                <div
                  class="relative flex items-center min-h-[3.5rem] px-13 w-[22rem] sm:w-[50rem] md:w-[60rem] rounded-xl border-2 border-n-3  text-0 after:bg-slate-300 after:absolute
                 after:bottom-0 after:h-3 after:w-0 mx-3 mb-2 bg-bgDark "
                >
                  <iconify-icon
                    icon="lets-icons:send-hor"
                    id="sendIcon"
                    width="30"
                    height="30"
                    style={{ color: "white" }}
                    onClick={sendChatInput}
                  ></iconify-icon>
                  <textarea
                    ref={textareaRef}
                    className="w-full md:pl-0 py-3 bg-transparent body2 text-n-7 outline-none ml-1
                        resize-none text-white border-none placeholder:text-n-4/75 focus:outline-none focus:border-none rounded-xl"
                    placeholder="اینجا جستوجو کنید"
                    spellCheck="false"
                    name="text_to_send"
                    value={textToSend}
                    onChange={onChatChange}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" && event.shiftKey) {
                        // Insert a new line in the textarea
                        event.preventDefault();
                        const startPos = event.target.selectionStart;
                        const endPos = event.target.selectionEnd;
                        setTextToSend(
                          (prevText) =>
                            prevText.substring(0, startPos) +
                            "\n" +
                            prevText.substring(endPos)
                        );
                      } else if (event.key === "Enter") {
                        event.preventDefault();
                        sendChatInput();
                        inputClear();
                        setTextToSend(" ");
                      }
                    }}
                    dir={/[A-Za-z]/.test(textToSend) ? "ltr" : "rtl"}
                    style={{ maxHeight: "15rem", overflowY: "auto" }}
                    onKeyUp={adjustTextareaHeight}
                    disabled={disabledTextarea}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mainpage;
