import { useContext } from "react";

import React, { useNavigate } from "react";

import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { confirmAlert } from "react-confirm-alert";

import { LogoutUser } from "../services/adminServices";
import ChatroomHis from "../components/ChatroomHis";

import {
  Dashboard,
  User,
  Accounts,
  SignOut,
  Purchase,
  Charge,
} from "../components";

import { signupformContext } from "../context/signupformContext";

const Insidenav = ({
  updateChatMessages,
  saveChatData,
  allChatData,
  onAllChatDataUpdate,
  handleNewChat,
  onClick,
  lastClickedChatRoomId,
}) => {
  const { LogoutForm } = useContext(signupformContext);

  const [activeComponent, setActiveComponent] = useState(null);

  // Update the active component when clicked
  const handleComponentClick = (component) => {
    setActiveComponent(component);
  };

  const colorLink = (event, targetLink) => {
    const linkColor = document.querySelectorAll(".nav_link");
    if (linkColor) {
      linkColor.forEach((l) => l.classList.remove("active"));
      targetLink.classList.add("active");
    }
  };

  const components = [
    { name: "history", component: <ChatroomHis /> },
    { name: "users", component: <User /> },
    { name: "account", component: <Accounts /> },
    { name: "signout", component: <SignOut /> },
    { name: "purchase", component: <Purchase /> },
    { name: "charge", component: <Charge /> },
  ];

  const closeNav = () => {
    const mySidenav = document.getElementById("mySidenav");
    mySidenav.style.width = "0";
  };

  const logOut = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              backgroundColor: "#28282B",
              borderRadius: "1em",
            }}
            className="p-4"
          >
            <h4 className="text-white">
              آیا تمایل دارید از اکانت خود خارج شوید؟
            </h4>
            <div className="row d-flex mt-3 gap-2">
              <button
                onClick={() => {
                  LogoutForm();
                  onClose();
                }}
                className="col btn text-white mb-2"
                style={{ backgroundColor: "#7289F4" }}
              >
                !بله
              </button>
              <button
                onClick={onClose}
                className="col btn text-white mb-2 rounded-3"
                style={{ backgroundColor: "#7289F4" }}
              >
                !خیر
              </button>
            </div>
          </div>
        );
      },
    });
  };
  return (
    <>
      <div
        id="mySidenav"
        className="sidenav flex flex-col fixed pt-0 top-0 text-center z-10 h-full overflow-hidden"
      >
        <div className="flex flex-row p-3 gap-2 h-full  ">
          <div className="flex flex-col text-white border rounded-4">
            <div className="flex flex-col gap-1.5 h-full mt-3">
              <a
                className="flex flex-col w-full  justify-center items-center no-underline"
                onClick={(e) => {
                  colorLink(e, e.currentTarget);
                  handleComponentClick("history");
                }}
              >
                <iconify-icon
                  icon="mingcute:chat-1-fill"
                  style={{ color: "white" }}
                ></iconify-icon>
                <span className="text-white font-thin w-2/3">تاریخچه</span>{" "}
              </a>

              <a
                className="flex flex-col mt-4 w-full  justify-center items-center no-underline "
                onClick={(e) => {
                  colorLink(e, e.currentTarget);
                  handleComponentClick("account");
                }}
              >
                <iconify-icon
                  icon="mingcute:grid-fill"
                  style={{ color: "white" }}
                ></iconify-icon>
                <span className="text-white font-thin mx-2 w-2/3">داشبورد</span>{" "}
              </a>
              <a
                className="flex flex-col mt-4 w-full  justify-center items-center no-underline"
                onClick={(e) => {
                  colorLink(e, e.currentTarget);
                  handleComponentClick("users");
                }}
              >
                <iconify-icon
                  icon="mage:user-fill"
                  style={{ color: "white" }}
                ></iconify-icon>
                <span className="text-white font-thin w-2/3">کاربران</span>{" "}
              </a>

              <a
                className="flex flex-col mt-4 w-full  justify-center items-center no-underline"
                onClick={(e) => {
                  colorLink(e, e.currentTarget);

                  handleComponentClick("purchase");
                }}
              >
                <iconify-icon
                  icon="bxs:purchase-tag-alt"
                  style={{ color: "white" }}
                ></iconify-icon>
                <span className="text-white font-thin w-2/3"> خرید اکانت</span>
              </a>

              <a
                className="flex flex-col mt-4 w-full  justify-center items-center no-underline"
                onClick={(e) => {
                  colorLink(e, e.currentTarget);
                  logOut();
                }}
              >
                <iconify-icon
                  icon="ri:logout-circle-fill"
                  style={{ color: "white" }}
                ></iconify-icon>{" "}
                <span className="text-white font-thin w-2/3">
                  خروج از اکانت
                </span>{" "}
              </a>
            </div>
          </div>

          <div className=" p-2 w-full  overflow-hidden">
            {activeComponent ? (
              activeComponent === "history" ? (
                // Render ChatroomHis if activeComponent is "history"
                <ChatroomHis
                  updateChatMessages={updateChatMessages}
                  saveChatData={saveChatData}
                  allChatData={allChatData}
                  onAllChatDataUpdate={onAllChatDataUpdate}
                  onClick={onClick}
                  handleNewChat={handleNewChat}
                  lastClickedChatRoomId={lastClickedChatRoomId}
                />
              ) : (
                // Render the corresponding component based on the activeComponent
                components.map((item, index) => {
                  if (item.name === activeComponent) {
                    return (
                      <React.Fragment key={index}>
                        {item.component}
                      </React.Fragment>
                    );
                  }
                  return null;
                })
              )
            ) : (
              // If no component is active, show the chathistory (default)
              <ChatroomHis
                updateChatMessages={updateChatMessages}
                saveChatData={saveChatData}
                allChatData={allChatData}
                onAllChatDataUpdate={onAllChatDataUpdate}
                onClick={onClick}
                handleNewChat={handleNewChat}
                lastClickedChatRoomId={lastClickedChatRoomId}
              />
            )}
          </div>
          <div className="flex flex-row-reverse">
            <Icon
              className="hover:cursor-pointer w-5 h-7 top-0"
              icon="eva:close-fill"
              data-toggle="collapse"
              style={{ color: "white" }}
              onClick={closeNav}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Insidenav;
