import { createContext } from 'react'
export const adminContext = createContext({
    admin: {},
    setAdmin: () => { },
    IsAdmin: {},
    SetIsAdmin: () => { },
    onAdminChange: () => { },
    createAdminForm: () => { },
    createForm: () => { },
    verifyForm: () => { },
    loading :{},
    setLoading:()=>{},
    passwordColor:{},
})