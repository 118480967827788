import React, { useState, useEffect } from 'react';
import hljs from 'highlight.js';
import '../css/dracula.css';

const TypewriterRecieved = ({ text }) => {
  const [preProcessedText, setPreProcessedText] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    // Function to check if text contains Persian characters (RTL)
    const isPersian = (text) => {
        const persianCharRegex = /[\u0600-\u06FF]/;
        return persianCharRegex.test(text);
    };

    const processedText = text.split('```').flatMap((section, i) => {
        if (i % 2 === 0) {
            // Regular text section
            const paragraphs = section.split(/\n\s*\n/).map((paragraph, index) => {
                // Remove quotations at the start and end of the paragraph
                const cleanedParagraph = paragraph.replace(/^("|\u201c)(.*?)(\1)$/mg, '\$2');
                const formattedParagraph = cleanedParagraph.replace(/\*\*(.*?)\*\*/g, '<b>\$1</b>'); // Apply bold formatting
                const sentences = formattedParagraph.split(/(?<!\d)\.\s+(?=\d)/);
    
                const formattedSentences = sentences.map((sentence, idx) => {
                    const direction = isPersian(sentence) ? 'rtl' : 'ltr';
                    return (
                        <p className='mx-3' key={`${i}-${index}-${idx}`} style={{ margin: "10px 10px", lineHeight: "2", direction }}>
                            <span className='text-base' dangerouslySetInnerHTML={{ __html: sentence }} />
                        </p>
                    );
                });
    
                return formattedSentences;
            });
    
            return paragraphs;
        } else {
            // Code snippet section
            return (
                <div key={i} className="code-snippet rounded-md" style={{ fontSize: "large", margin: "0px 10px", lineHeight: "1.7", backgroundColor: "#2B2B2B" }}>
                    <pre>
                        <code className="hljs rounded-xl" dangerouslySetInnerHTML={{ __html: hljs.highlightAuto(section).value }} />
                    </pre>
                </div>
            );
        }
    });
    setPreProcessedText(processedText);
}, [text]);



  return (
    <div >
      {preProcessedText}
    </div>
  );
};

export default TypewriterRecieved;