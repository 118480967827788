let notfound = require("../assets/Animation.gif");

const Notfound = () => {
  return (
    <div className="flex justify-center items-center p-5 m-5">
      <img style={{ height: "45vh" }} src={notfound}></img>
    </div>
  );
};

export default Notfound;
