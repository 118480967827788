import axios from 'axios'
import Cookies from 'js-cookie';

const SERVER_URL = "https://chat.ivaai.ir/";

const csrftoken = Cookies.get('csrftoken');

const token = localStorage.getItem("token");

export const createCompany = async (company) => {

  const url = `${SERVER_URL}registerCompany/`;

  const token = localStorage.getItem("token");

  // console.log("token is: " , token)
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
    'X-CSRFToken': csrftoken,
  };
  // console.log(company)
  return axios.post(url, company, { headers });
};

export const getCompanyData = () => {
  const url = `${SERVER_URL}getCompany/`;
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = axios.get(url, { headers });
  // console.log(response);
  return response;
};

export const getProductData = () => {
  const url = `${SERVER_URL}product/`;
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = axios.get(url, { headers });
  return response;
};


export const InfoCompanyAfterPay = () => {
  const url = `${SERVER_URL}infoCompanyAfterPay/`;
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = axios.get(url, { headers });
  // console.log(response)
  return response;
};
