import { useContext } from "react";

import React, { useState, useEffect } from "react";

import ChatroomHis from "../components/ChatroomHis";
import Settinglg from "./Settinglg";

import { UserContext } from "../context/userContext";
import NewChatBtn from "./NewChatBtn";

let profile = require("../assets/profilee.jpg");

const Secondsidenav = ({
  updateChatMessages,
  saveChatData,
  allChatData,
  onAllChatDataUpdate,
  handleNewChat,
  onClick,
  lastClickedChatRoomId,
}) => {
  const { allUsers, checkLogin } = useContext(UserContext);
  
  const [showSettinglg, setShowSettinglg] = useState(false);
  const handleSettinglgOpen = () => {
    setShowSettinglg(true);
  };

  const handleSettinglgClose = () => {
    setShowSettinglg(false);
  };

  return (
    <>
      <div className="flex flex-col w-full h-screen relative">
        <div className="flex-row justify-center py-2 mx-2">
          <NewChatBtn handleNewChat={handleNewChat} />
        </div>{" "}
        <span className="text-gray-400 text-center text-md ">
          تاریخچه چت ها{" "}
        </span>
        <div className="flex flex-col flex-1 sm:max-h-64 md:max-h-80 lg:max-h-80 w-full h-full relative hover:cursor-pointer">
          <ChatroomHis
            updateChatMessages={updateChatMessages}
            saveChatData={saveChatData}
            allChatData={allChatData}
            onAllChatDataUpdate={onAllChatDataUpdate}
            onClick={onClick}
            handleNewChat={handleNewChat}
            lastClickedChatRoomId={lastClickedChatRoomId}
          />
        </div>
        <div className="w-full absolute bottom-0 mb-0  bg-bgDark rounded-lg ">
          <button
            className="flex-1 w-full rounded-lg mx-0 p-0 h-13 mb-2  hover:bg-[#76767A]"
            onClick={handleSettinglgOpen}
          >
            <div className="flex flex-row w-full items-center gap-1 p-2">
              <img src={profile} className="rounded-full w-7 h-7" />
              <div className="flex flex-col mx-0 text-start overflow-hidden w-full ">
                {checkLogin ? (
                  <>
                    <span className="text-white w-full ">
                      {allUsers.data.full_name}
                    </span>
                    <span className="text-white "> {allUsers.data.email}</span>
                  </>
                ) : (
                  <>
                    {allUsers ? (
                      <>
                        <span className="text-white text-xs">
                          {allUsers.full_name}
                        </span>
                        <span className="text-gray-400 text-xs">
                          {" "}
                          {allUsers.email}
                        </span>
                      </>
                    ) : (
                      "Oops"
                    )}
                  </>
                )}
              </div>
            </div>
          </button>
        </div>
        {showSettinglg && (
          <Settinglg handleSettinglgClose={handleSettinglgClose} />
        )}
      </div>
    </>
  );
};

export default Secondsidenav;
