import { useContext, useState } from "react";

import { UserContext } from "../context/userContext";
import { CompanyContext } from "../context/companyContext";

import Users from "./Users";
import {  toast } from 'react-hot-toast';

import Button from "@mui/material/Button";
import { SnackbarProvider, useSnackbar } from "notistack";

import { createUser } from "../services/userServices";

const User = () => {
  const { compUsers, user, setUser } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  const { enqueueSnackbar } = useSnackbar();

  const handleClickVariant = (variant) => () => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar("کاربر جدید با موفقیت ساخته شد.!", { variant });
    window.location.href = '/chat';


  };

  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);

  const toggleAdditionalButtons = () => {
    setShowAdditionalButtons(!showAdditionalButtons);
  };

  const createUserForm = async (event) => {
    event.preventDefault();
    try {
      const response = await createUser(user);
      if (response.status === 201) {
        toast.success('!  کاربر با موفقیت ساخته شد  🎉');
        window.location.href = '/chat';

      } else{
        toast.error(('این نام یا ایمیل قبلا ثبت شده است!'), {
          style: {
            background: "red",
            color: "white"
          }})
      }
    } catch (err) {
      toast.error(('این نام یا ایمیل قبلا ثبت شده است!'), {
        style: {
          background: "red",
          color: "white"
        }})
    }
  };

  const onUserChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  return (
    <>
      <div
        className="flex flex-col" >
        <div className="flex flex-auto  justify-center ">
          <button
            onClick={toggleAdditionalButtons}
            className={`w-full h-12 rounded-3 align-middle  items-center  ${
              company.Number_user > 0 ? "active" : "inactive"
            }`}
            style={{
              backgroundColor: "#7289F4",
              cursor: company.Number_user > 0 ? "pointer" : "not-allowed",
            }}
            disabled={company.Number_user === 0} // Disable button when inactive
          >
            <span className="text-white text-center text-lg">دعوت</span>
          </button>
        </div>
        <div className="flex flex-auto mt-2 text-end">
          <span className="text-gray-400 text-end text-lg">
            {company.Number_user} تعداد کاربران
          </span>
        </div>{" "}
        <hr className="w-full" style={{ color: "gray" }} />
        <div
          className="w-full "
          style={{ display: showAdditionalButtons ? "flex" : "none" }}
        >
          <form
            onSubmit={createUserForm}
            className="p-4 w-full bg-inherit"
          >
            <input
              className="rounded-md mb-2 h-8 w-full text-gray-900"
              type="email"
              placeholder="ایمیل"
              required={true}
              name="email"
              value={user.email}
              onChange={onUserChange}

            />
            <input
              className="rounded-md mb-2 h-8 w-full text-gray-900"
              type="text"
              placeholder="تلفن همراه "
              required={true}
              name="phone_number"
              value={user.phone_number}
              onChange={onUserChange}
         
            />
            <input
              className="rounded-md mb-2 h-8 w-full text-gray-900"
              type="text"
              placeholder="  نام و نام خانوادگی"
              required={true}
              name="full_name"
              value={user.full_name}
              onChange={onUserChange}
            
            />
            <input
              className="rounded-md mb-2 h-8 w-full text-gray-900"
              type="password"
              placeholder="رمز ورود"
              name="password"
              required={true}
              value={user.password}
              onChange={onUserChange}
           
            />
            <input
              className="rounded-md mb-2 h-8 w-full text-gray-900"
              type="password"
              placeholder="تکرار رمز ورود "
              name="password2"
              required={true}
              value={user.password2}
              onChange={onUserChange}
             
            />
            <div className="flex w-1/2">
              <button
                type="submit"
                className="rounded-lg btn-continue  w-full h-12  mb-3 z-2"
                >
                <span className="btn-text text-slate-200 text-lg" >
                  {" "}
                  تایید
                </span>{" "}
              </button>
            </div>
          </form>
        </div>
        <div className="flex flex-row w-full p-1  shadow-md">
          <Users hideNoUsers={showAdditionalButtons} />
        </div>
      </div>
    </>
  );
};

export default User;
