
const SignOut=()=>{
  
    return(
        <>
        sign out in here
        </>
    )
}

export default SignOut;