import { createContext, useContext, useState } from 'react';

const ChatroomContext = createContext();

export const ChatroomProvider = ({ children }) => {
  const [currentChatroomId, setCurrentChatroomId] = useState(null);

  // const [chatHistory, setChatHistory] = useState([]);
  // const [previousChatroomIds, setPreviousChatroomIds] = useState([]);

  // const handleChatHistoryUpdate = (newMessage) => {
  //     setChatHistory((prevHistory) => [...prevHistory, newMessage]);
  // };

  // const addChatroomToPreviousList = (chatroomId) => {
  //   setPreviousChatroomIds([...previousChatroomIds, chatroomId]);
  // };

  return (
    <ChatroomContext.Provider value={{ currentChatroomId, setCurrentChatroomId   }}>
      {children}
    </ChatroomContext.Provider>
  );
};

export const useChatroom = () => useContext(ChatroomContext);
