import axios from 'axios'
import Cookies from 'js-cookie';

const SERVER_URL = "https://chat.ivaai.ir/";

export const newChat = async () => {

  const token = localStorage.getItem("token");
  // console.log(token)

  const url = `${SERVER_URL}chatrooms/`;

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };
  return axios.post(url, {}, { headers });

}


export const SendChat = async (textToSend) => {
  const token = localStorage.getItem("token");
  const url = `${SERVER_URL}chatgpt/`;

  const payload = JSON.stringify({
    text_to_send: JSON.stringify(textToSend)
  });


  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };
  const response = await axios.post(url, payload, { headers })
  // console.log(response)
  return response
};

export const SendChat4 = async (textToSend) => {
  const token = localStorage.getItem("token");
  const url = `${SERVER_URL}chatgpt4/`;

  const payload = JSON.stringify({
    text_to_send: JSON.stringify(textToSend)
  });
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };
  const response = await axios.post(url, payload, { headers })

  return response
};

export const Chat4o = async (textToSend) => {
  const token = localStorage.getItem("token");
  const url = `${SERVER_URL}chatgpt4o/`;

  const payload = JSON.stringify({
    text_to_send: JSON.stringify(textToSend)
  });
  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };
  const response = await axios.post(url, payload, { headers })

  return response
};

export const generatorImg = async (textToSend) => {
  const token = localStorage.getItem("token");
  const url = `${SERVER_URL}generateimage/`;

  const payload = JSON.stringify({
    text_to_send: textToSend
  });

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await axios.post(url, payload, { headers });

  if (response.status === 200) {
    // console.log(response.data)
    return response.data; // Return the image URL data
  } else {
    throw new Error("Failed to fetch image URL");
  }
};


export const getImageGenerator = async () => {
  const token = localStorage.getItem("token");
  const url = `${SERVER_URL}gets/`;

  const headers = {
    Authorization: `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await axios.get(url, { headers });
  console.log( response.data.image_url)
  return response.data.image_url;
}

  export const DallE = async (textToSend) => {
    const token = localStorage.getItem("token");
    const url = `${SERVER_URL}dalle3/`;

    const payload = JSON.stringify({
      text_to_send: JSON.stringify(textToSend)
    });
    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.post(url, payload, { headers })
    // console.log(response.data);
    const imgUrl = response.data
    return imgUrl
  };

  export const AllchatRooms = async () => {
    const token = localStorage.getItem("token");
    const url = `${SERVER_URL}chatrooms/`;

    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };

    const response = await axios.get(url, { headers });
    const chatRooms = response.data;

    return chatRooms;
  };


  export const AllChatRoomsId = async (chatroomId) => {
    try {
      const token = localStorage.getItem("token");
      const url = `${SERVER_URL}chatrooms/${chatroomId}/`;

      const headers = {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      };

      const response = await axios.get(url, { headers });

      if (response.status === 200) {
        // console.log(response.data);
        return response.data;
      } else {
        throw new Error("Failed to fetch chat room messages");
      }
    } catch (error) {
      // console.error("Error fetching chat room messages:", error);
      return null;
    }
  };


  // purchasing products
  export const addToCart = async (product_id, quantity = 1) => {
    const url = `${SERVER_URL}cart/add/${product_id}/`;
    const token = localStorage.getItem("token");

    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };
    return axios.post(url, { quantity }, { headers });

  };

  export const getOrderId = async (order_id) => {
    const token = localStorage.getItem("token");
    const url = `${SERVER_URL}pay/${order_id}/`;

    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.get(url, { headers });
    // const chatRooms = response.data;
    // console.log(response)
    return response;
  };

  export const getOrderIdBehpardakht = async (order_id) => {
    const token = localStorage.getItem("token");
    const url = `${SERVER_URL}pay2/${order_id}/`;

    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.get(url, { headers });
    // const chatRooms = response.data;
    return response;
  };

  export const checkVerify = async () => {
    const token = localStorage.getItem("token");
    const url = `${SERVER_URL}paid-ids/`;


    const headers = {
      Authorization: `Token ${token}`,
      'Content-Type': 'application/json',
    };
    const response = await axios.get(url, { headers });
    // console.log(response.data);
    return response;

  };