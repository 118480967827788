import * as React from "react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { checkVerify } from "../services/chatGpt";
import { UserContext } from "../context/userContext";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { darkblue } from "../helper/color";

const PurchaseInfo = () => {
  const {IsPayde , setIsPayed}=useContext(UserContext);

  const navigate = useNavigate();


  const NavMainPage = () => {
    navigate("/chat");
  };
  return (
    <>
      <div className="container-fluid d-flex align-items-center"
      id="purchaseInfoSm"
      >
        <div className="w-full">
          <Card
            className="rounded-4 mx-1"
            sx={{
              // minWidth: 275,
              backgroundColor: "#171717",
            }}
          >
            <CardContent>
              <Typography
                className="text-center"
                sx={{ fontSize: 15, color: "white" }}
                gutterBottom
              >
                کاربر گرامی{" "}
              </Typography>
              {IsPayde ? (
                <>
                  <Typography
                    sx={{ mb: 1.5, color: "white" }}
                    className=" text-center m-3 "
                  >
                    <iconify-icon
                      icon="line-md:check-all"
                      width="1.2em"
                      style={{ color: "green" }}
                    ></iconify-icon>
                    &nbsp; پرداخت شما با موفقیت انجام شد
                  </Typography>
                  <Typography variant="body2 d-flex justify-content-center">
                    <p className="text-center text-white">
                      {" "}
                      کد ارجاع خرید شما :{" "}
                      <span className="text-secondary h5 text-center"> {IsPayde}</span>
                    </p>{" "}
                    <br />
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{ mb: 1.5, color: "white" }}
                    className=" text-center m-3 "
                  >
                    <iconify-icon
                      icon="line-md:menu-to-close-alt-transition"
                      style={{ color: " #a30000" }}
                    ></iconify-icon>
                    خرید شما با موفقیت انجام نشد{" "}
                  </Typography>
                </>
              )}
            </CardContent>
            <CardActions className="d-flex justify-content-center">
              <Button
                onClick={NavMainPage}
                className="w-100 mx-5 m-2"
                style={{ backgroundColor: darkblue, color: "white" }}
              >
                بازگشت به سایت
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>


      
    </>
  );
};

export default PurchaseInfo;
