import { newChat, AllchatRooms } from "../services/chatGpt";

import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../context/chatGpt";

const NewChatBtn = ({ handleNewChat }) => {
  const { setChatRooms } = useContext(ChatContext);
  const [chatbtn, setChatbtn] = useState(false);

  // Function to fetch chat rooms again whenever new room is created
  const handleNewChatRoom = async () => {
    try {
      const response = await AllchatRooms();
      if (Array.isArray(response)) {
        const formattedChatRooms = response.map((chatRoom) => {
          return chatRoom;
        });
        setChatRooms(formattedChatRooms);
      } else {
        // console.log("Invalid response format");
      }
    } catch (error) {
      // console.log("Error fetching chats:", error);
    }
  };

  const chatButton = () => {
    newChat();
    handleNewChatRoom();
    handleNewChat();
    setChatbtn(true);
  };


  return (
    <>
      <button
        className="btn-service flex flex-1 w-full justify-center rounded-md h-10 p-1 gap-1"
        onClick={chatButton}
        style={{backgroundColor:"#7289F4"}}
      >
        <svg  
          height="24"
          width="24"
          fill="#FFFFFF"
          viewBox="0 0 24 24"
          data-name="Layer 1"
          id="Layer_1"
          class="sparkle"
        >
          <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
        </svg>
        {chatbtn ? (
          
          <span className="service-text"> چت جدید </span>
        ) : (
          <span className="service-text"> شروع چت </span>
        )}
      </button> 
    </>
  );
};

export default NewChatBtn;
