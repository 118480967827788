import React, { useEffect, useState, useContext, useRef } from "react";

import "react-chat-elements/dist/main.css";

import { SendChat, newChat, AllchatRooms } from "../services/chatGpt";

import ChatroomHis from "./ChatroomHis";
import { CompanyContext } from "../context/companyContext";
import ChatRoomComponent from "./ChatroomComponent";

import { Input, ChatIn } from "react-chat-elements";
import { Button } from "react-chat-elements";
import { MessageBox } from "react-chat-elements";

import { backgroundblue } from "../helper/color";
import "../css/chat.css";
import { Icon } from "@iconify/react";
import NewChatBtn from "./NewChatBtn";
import Spinner from "./Spinner";

let logo = require("../assets/favicon.ico.png");
let profile = require("../assets/profilee.jpg");

const Chat = () => {
  const isMobileWidth = window.innerWidth < 768;
  const isnewchat = window.innerWidth < 601;

  const [inputActive, setInputActive] = useState(false);

  const [loading, setloading] = useState(false);

  const [isChatVisible, setChatVisible] = useState(false);

  const handleHamburgerClick = () => {
    setChatVisible(!isChatVisible);
    // console.log("hamberger");
  };

  //sendinput function activated or not
  const [showChatroomHis, setShowChatroomHis] = useState(false);

  const [textToSend, setTextToSend] = useState("");

  const [currentChatroom, setCurrentChatroom] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);

  const [chatResponse, setchatResponse] = useState([]);
  //chatrooms chats history
  const [chatHisMess, setChatHisMess] = useState([]);
  const [chatHisResponse, setChatHisResponse] = useState([]);

  //chatrooms
  const [chatRooms, setChatRooms] = useState([]);

  const [chatMessagesPrev, setChatMessagesPrev] = useState([]);

  const [updatedChatMessages, setUpdatedChatMessages] = useState([]);
  //last clicked chatroom
  const [lastClickedChatRoomId, setLastClickedChatRoomId] = useState(null);

  //keep track of clicked component in the chatroomcomponent
  const [clickedComponent, setClickedComponent] = useState(false);

  //chatrooms chats
  const [allChatData, setAllChatData] = useState([]);

  const handleChildClick = (chatRoomId) => {
    setClickedComponent(true);
    setLastClickedChatRoomId(chatRoomId);
    // console.log("its clicked");
    // console.log(chatRoomId);
  };

  // Save the last clicked chatroom ID in local storage
  useEffect(() => {
    localStorage.setItem("lastClickedChatRoomId", lastClickedChatRoomId);
  }, [lastClickedChatRoomId]);

  // Retrieve the last clicked chatroom ID when component mounts
  useEffect(() => {
    const lastClickedId = localStorage.getItem("lastClickedChatRoomId");
    if (lastClickedId) {
      setLastClickedChatRoomId(lastClickedId);
    }
  }, []);

  const handleAllChatDataUpdate = (updatedData, clickedChatRoomId) => {
    const newChatMess = updatedData.map((chatItem) => chatItem.text_to_send);
    const newChatResponse = updatedData.map(
      (chatItem) => chatItem.text_to_receive
    );

    setChatHisMess(newChatMess);
    setChatHisResponse(newChatResponse);

    // Update the chat messages received from ChatroomHis in the parent component
    setUpdatedChatMessages(updatedData);
  };

  const onChatChnage = (event) => {
    setTextToSend(event.target.value);
  };

  const sendChatInput = async () => {
    // console.log("button is clicked");
    setChatMessages((prevMessages) => [...prevMessages, textToSend]);
    // console.log("Message sent:", textToSend);

    try {
      setloading(true);

      const responseData = await SendChat(textToSend);
      inputClear();
      setTextToSend(" ");
      const response = responseData.data.response;
      // console.log("Response data:", response);
      setchatResponse((prevResponses) => [...prevResponses, response]);
      setloading(false);
      setShowChatroomHis(true);

      // Set the currentChatroom state with the chatroom data after the first message is sent
      if (!currentChatroom) {
        setCurrentChatroom(chatRooms[0]); // Assuming you want to use the first chatroom
      }
      // Update chat history with new message data
      const updatedData = [
        ...updatedChatMessages,
        { text_to_send: textToSend, text_to_receive: response },
      ];
      setUpdatedChatMessages(updatedData);
    } catch (err) {
      setloading(false);
      // console.log(err.message);
    }
  };

  useEffect(() => {
    // console.log(textToSend);
  }, [textToSend]);

  const inputClear = () => {};

  // Function to update chat messages in the parent component
  const updateChatMessages = (messages) => {
    setChatMessagesPrev(messages);
  };

  //chats returning from child
  const [chatRoomsData, setChatRoomsData] = useState([]);
  // Your logic to fetch and process chat data

  const saveChatData = (chatData) => {
    setChatRoomsData(chatData);
  };

  useEffect(() => {
    // console.log(chatRoomsData);
  }, [chatRoomsData]);

  const handleNewChat = () => {
    setChatMessages([]);
    setchatResponse([]);
    setChatHisMess([]);
    setChatHisResponse([]);
    setClickedComponent(false);
    setInputActive(true);
  };
  // Function to fetch chat rooms again whenever new room is created
  const handleNewChatRoom = async () => {
    try {
      const response = await AllchatRooms();
      if (Array.isArray(response)) {
        const formattedChatRooms = response.map((chatRoom) => {
          return chatRoom;
        });
        setChatRooms(formattedChatRooms);
      } else {
        // console.log("Invalid response format");
      }
    } catch (error) {
      // console.log("Error fetching chats:", error);
    }
  };

  const chatButton = () => {
    newChat();
    handleNewChatRoom();
    handleNewChat();
  };

  return (
    <>
      <div className="row rounded-5 main-container m-0 pt-5">
        <div className="row mx-0 mt-4">
          {isnewchat ? (
            <>
              <div
                style={{
                  backgroundColor: "#333C4F",
                  borderTopLeftRadius: "15px",
                  borderTopRightRadius: "15px",
                }}
              >
                <div className="d-flex justify-content-between mx-2">
                  <span className="newchat mt-1" onClick={chatButton}>
                    {" "}
                    <iconify-icon
                      icon="icons8:plus"
                      width="4vw"
                      id="newchat"
                    ></iconify-icon>
                  </span>

                  <button class="button-explore p-1 mt-2 d-flex justify-content-center rounded-5">
                    <svg
                      className="svgIcon"
                      viewBox="0 0 512 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path>
                    </svg>
                  </button>

                  <div
                    className="hamburger-menu"
                    onClick={handleHamburgerClick}
                  >
                    {isChatVisible ? (
                      <Icon
                        icon="eva:close-fill"
                        data-toggle="collapse"
                        style={{
                          transform: "translateX(0px)",
                          zIndex: "20",
                          color: "white",
                        }}
                        width="1rem"
                      />
                    ) : (
                      <iconify-icon
                        icon="mingcute:menu-fill"
                        id="hambermenu"
                        className="mt-1"
                      ></iconify-icon>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                className="col-sm-2 col-md-2 col-lg-2 d-flex mx-0"
                style={{
                  backgroundColor: "#333C4F",
                  borderTopLeftRadius: "25px",
                }}
              >
                <div className="d-flex h-50 mt-2 ">
                  <img
                    src={logo}
                    className="position-relative"
                    // style={{ width: "48px", height: "32px" }}
                  ></img>
                  <p className="text-bold text-capitalize text-center mt-2 h-50 Ivatext">
                    The IVA Demo
                  </p>
                </div>
              </div>
              <div
                className="col explorediv"
                style={{
                  background: "linear-gradient(to right, #333C4F, #F7F6FB)",
                }}
              >
                <div className="col-sm-1 col-md-2 col-lg-2 d-flex mx-0 mt-2 w-25 d-flex justify-content-start">
                  <button class="button-explore p-1  d-flex justify-content-start">
                    <svg
                      class="svgIcon"
                      viewBox="0 0 512 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm50.7-186.9L162.4 380.6c-19.4 7.5-38.5-11.6-31-31l55.5-144.3c3.3-8.5 9.9-15.1 18.4-18.4l144.3-55.5c19.4-7.5 38.5 11.6 31 31L325.1 306.7c-3.2 8.5-9.9 15.1-18.4 18.4zM288 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row chat-area mx-0 rounded" style={{ height: "73vh" }}>
          <div
            className="col-2 h-50 h-100 mx-0 forphones"
            style={{
              backgroundColor: "#333C4F",
              BorderBottomLeftRadius: "15px",
            }}
          >
            <p className="text-white text-capitalize text-center mt-2">
              تاریخچه چت ها{" "}
            </p>
            {chatMessages.length > 0 && currentChatroom && (
              <ChatRoomComponent chatRoom={currentChatroom} />
            )}
            {/* {showChatroomHis && (
                <ChatroomHis
                  saveChatData={saveChatData}
                  allChatData={allChatData}
                  onAllChatDataUpdate={handleAllChatDataUpdate}
                  onClick={handleChildClick}
                  lastClickedChatRoomId={lastClickedChatRoomId}
                />
              )} */}
            <ChatroomHis
              updateChatMessages={updateChatMessages}
              saveChatData={saveChatData}
              allChatData={allChatData}
              onAllChatDataUpdate={handleAllChatDataUpdate}
              onClick={handleChildClick}
              handleNewChat={handleNewChat}
              lastClickedChatRoomId={lastClickedChatRoomId}
            />
            <NewChatBtn handleNewChat={handleNewChat} />{" "}
          </div>
          <div
            className="col chatpart rounded-bottom h-100"
            style={{
              background: "linear-gradient(to right, #333C4F, #F7F6FB)",
            }}
          >
            {isChatVisible ? (
              <div className="scrollbar w-100 h-100" style={{ zIndex: "999" }}>
                <ChatroomHis
                  updateChatMessages={updateChatMessages}
                  saveChatData={saveChatData}
                  allChatData={allChatData}
                  onAllChatDataUpdate={handleAllChatDataUpdate}
                  onClick={handleChildClick}
                  handleNewChat={handleNewChat}
                  lastClickedChatRoomId={lastClickedChatRoomId}
                />
              </div>
            ) : (
              <>
                <div
                  className=" chatscreen mt-2 rounded-3 scrollbar2"
                  id="style-3"
                >
                  <div className="chat-messages p-2 ">
                    {clickedComponent ? (
                      <>
                        {updatedChatMessages &&
                          updatedChatMessages.length > 0 &&
                          updatedChatMessages.map((chatItem, index) => (
                            <React.Fragment key={index}>
                              <div
                                className="message sent-message rounded-3 p-1 ms-5 mt-3"
                                id="sendChat"
                              >
                                <div className="ms-3 d-flex justify-content-end mb-3 pt-1">
                                  <img
                                    className="profileIcon rounded-5 mx-2"
                                    src={profile}
                                  />
                                </div>
                                <p className="text-white ms-3">
                                  {chatItem.text_to_send}
                                </p>
                                <span>{chatItem.timestamp}</span>
                              </div>
                              <div
                                className="message received-message rounded-3 p-1 ms-5 mt-4 mb-5"
                                id="recievedChat"
                              >
                                <div className="ms-3 d-flex justify-content-end mb-2 mt-2">
                                  <span className="d-flex align-items-center text-white">
                                    The IVA Free
                                  </span>

                                  <img className="logoInChat " src={logo} />
                                </div>
                                <p className="text-white">
                                  {chatItem.text_to_recive}
                                </p>
                                <span>{chatItem.timestamp}</span>
                              </div>
                            </React.Fragment>
                          ))}
                      </>
                    ) : (
                      <>
                        {chatMessages.map((userMessage, index) => (
                          <React.Fragment key={index}>
                            <div
                              className="message user-message rounded-3  p-1 ms-5"
                              id="sendChat"
                            >
                              <div className="ms-3 d-flex justify-content-end mb-3 mt-2 h-25">
                                <img
                                  className="profileIcon rounded-5 mx-2"
                                  src={profile}
                                />
                              </div>
                              <p className="text-white ms-3 "> {userMessage}</p>
                            </div>
                            {index === chatMessages.length - 1 && loading ? (
                              <Spinner />
                            ) : (
                              <>
                                {chatResponse[index] && (
                                  <div
                                    className="message chat-response rounded-3 p-1 ms-5 mt-4 mb-2"
                                    id="recievedChat"
                                  >
                                    <div className="ms-3 d-flex justify-content-end mb-3 mt-2 h-25">
                                      <span className="d-flex align-items-center text-white">
                                        The IVA Free
                                      </span>

                                      <img className="logoInChat " src={logo} />
                                    </div>
                                    <p
                                      className="recievedChatP text-white h-25 m-2"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {chatResponse[index]}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="col rounded-4 border d-flex chat-input-area
             ms-4 align-items-center"
                >
                  <iconify-icon
                    icon="lets-icons:send-hor"
                    id="sendIcon"
                    width="32"
                    height="32"
                  ></iconify-icon>
                  {inputActive ? (
                    <textarea
                      dir="rtl"
                      className="chat-input rounded-4 text-white w-100 ms-4 "
                      placeholder="اینجا جستوجو کنید"
                      spellCheck="false"
                      name="text_to_send"
                      value={textToSend}
                      onChange={onChatChnage}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && event.shiftKey) {
                          // Insert a new line in the textarea
                          event.preventDefault();
                          const startPos = event.target.selectionStart;
                          const endPos = event.target.selectionEnd;
                          setTextToSend(
                            (prevText) =>
                              prevText.substring(0, startPos) +
                              "\n" +
                              prevText.substring(endPos)
                          );
                        } else if (event.key === "Enter") {
                          event.preventDefault(); // Prevent the default Enter key behavior (like submitting the form)
                          sendChatInput(); // Call your send message function here
                        }
                      }}
                    />
                  ) : (
                    <textarea
                      dir="rtl"
                      disabled={!inputActive}
                      className="chat-input rounded-4 text-white w-100 ms-4"
                      placeholder="اینجا جستوجو کنید"
                      spellCheck="false"
                      name="text_to_send"
                      value={textToSend}
                      onChange={onChatChnage}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && event.shiftKey) {
                          // Insert a new line in the textarea
                          event.preventDefault();
                          const startPos = event.target.selectionStart;
                          const endPos = event.target.selectionEnd;
                          setTextToSend(
                            (prevText) =>
                              prevText.substring(0, startPos) +
                              "\n" +
                              prevText.substring(endPos)
                          );
                        } else if (event.key === "Enter") {
                          event.preventDefault(); // Prevent the default Enter key behavior (like submitting the form)
                          sendChatInput(); // Call your send message function here
                        }
                      }}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
