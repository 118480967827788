import { useContext } from "react";
import { useState, useEffect } from "react";
import { getProductData } from "../services/companyServices";
import Shopbtn from "./Shopbtn";
import { CompanyContext } from "../context/companyContext";
const Purchase = () => {
  const { company } = useContext(CompanyContext);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const fetchProductInfo = async () => {
      try {
        const response = await getProductData();
        // console.log(response);

        // assuming company.Number_user is the number of users for the company
        const companyNumberOfUsers = company.Number_user;

        const productsData = response.data.filter(
          (product) => product.NumberOfUser === companyNumberOfUsers
        );

        if (productsData.length > 0) {
          setProductData(productsData);
          // console.log("Products with matching number of users:", productsData);
        } else {
          // console.log("No products found with matching number of users.");
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    fetchProductInfo();
  }, []);

  return (
    <>
      <div
        className="flex flex-col  w-full rounded-lg h-screen overflow-y-scroll md:h-full p-1 md:overflow-hidden"
        id="style-3"
      >
        <div className="p-1 md:flex  md:grid-cols-3 md:gap-2 ">
          {" "}
          <div className="flex flex-col mx-0 md:flex-1">
            <div className="flex flex-row">
              <div className="flex flex-1 justify-end m-1.5">
                <p className=" text-white text-2xl">برنز</p>
              </div>
              <div className="mt-1">
                <iconify-icon
                  icon="line-md:star-pulsating-loop"
                  width="3rem"
                  height="3rem"
                  style={{ color: "#CD7F32" }}
                ></iconify-icon>
              </div>
            </div>
            <p className=" text-gray-400" style={{ direction: "rtl" }}>
              {productData.length > 0 && (
                <div>
                  <p className="text-center text-gray-400">
                    {productData[0].price} / ماهانه
                  </p>
                  <p className="text-center  text-gray-500">
                    تعداد کاربران {productData[0].NumberOfUser}
                  </p>
                  <div className="flex flex-col w-full justify-center p-2.5">
                    <Shopbtn productId={productData[0].id} />{" "}
                  </div>
                </div>
              )}
              {productData.length === 0 && <p>کالایی پیدا نشد</p>}
            </p>

            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکانات چت به صورت نامحدود با IVA که یک هوش مصنوعی است با
                  استفاده از CHATGPT3.5
                </p>
              </div>
            </div>
            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکان ساخت 10 عکس توسط IVA PIC با کیفیت HD که متن را به عکس
                  تبدیل می کند.
                </p>
              </div>
            </div>
            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکان استفاده بدون محدودیت
                </p>
              </div>{" "}
            </div>
            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکان استفاده به صورت همزمان برای تعداد کاربران ساخته شده
                </p>
              </div>
            </div>
          </div>
          <hr style={{ color: "white" }} />
          <div className="flex flex-col mx-0 md:flex-1">
            <div className="flex flex-row">
              <div className="flex flex-1 justify-end m-1.5">
                <p className=" text-white text-2xl">سیلور</p>
              </div>
              <div className=" mt-1">
                <iconify-icon
                  icon="line-md:star-pulsating-loop"
                  width="3rem"
                  height="3rem"
                  style={{ color: " #065d7a" }}
                ></iconify-icon>
              </div>
            </div>
            <p className=" text-gray-400" style={{ direction: "rtl" }}>
              {productData.length > 0 && (
                <div>
                  <p
                    className="text-center text-gray-400"
                    style={{ fontSize: "18px" }}
                  >
                    {productData[2].price} / ماهانه
                  </p>
                  <p className="text-center text-gray-400">
                    تعداد کاربران {productData[2].NumberOfUser}
                  </p>
                  <div className="flex flex-col w-full justify-center p-2.5">
                    <Shopbtn productId={productData[2].id} />{" "}
                    {/* Pass the product ID as a prop */}
                  </div>
                </div>
              )}
              {productData.length === 0 && <p>کالایی پیدا نشد</p>}
            </p>
            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکانات چت به صورت نامحدود با IVA PRO با پاسخگویی تخصوصی تر با
                  استفاده از CHATGPT4
                </p>
              </div>
            </div>
            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکان ساخت نامحدود عکس توسط IVA PIC با کیفیت FULL HD که متن را
                  به عکس تبدیل می کند.
                </p>
              </div>
            </div>
            <div className="flex flex-1 text-right w-full">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکان ساخت 5 عدد عکس FULL HD توسط IVA PIC PRO با استفاده از
                  DALL-E 3 که متن را به عکس تبدیل می کند.{" "}
                </p>
              </div>{" "}
            </div>
          </div>
          <hr style={{ color: "white" }} />
          <div className="lex flex-col mx-0 md:flex-1">
            <div className="flex flex-row">
              <div className="flex flex-1 justify-end m-1.5">
                <p className=" text-white  text-2xl">طلایی</p>
              </div>
              <div className=" mt-1">
                <iconify-icon
                  icon="line-md:star-pulsating-loop"
                  width="3rem"
                  height="3rem"
                  style={{ color: " #ffbb00" }}
                ></iconify-icon>
              </div>
            </div>
            <p className=" text-gray-400" style={{ direction: "rtl" }}>
              {productData.length > 0 && (
                <div>
                  <p
                    className="text-center  text-gray-400"
                    style={{ fontSize: "18px" }}
                  >
                    {productData[1].price} / ماهانه
                  </p>
                  <p className="text-center  text-gray-400">
                    تعداد کاربران {productData[1].NumberOfUser}
                  </p>
                  <div className="flex flex-col w-full justify-center p-2.5">
                    <Shopbtn productId={productData[1].id} />{" "}
                    {/* Pass the product ID as a prop */}
                  </div>
                </div>
              )}
              {productData.length === 0 && <p>کالایی پیدا نشد</p>}
            </p>

            <div className="flex flex-1 text-right w-full mb-5">
              <div className="flex flex-1">
                <iconify-icon
                  icon="uis:check"
                  width="1.2rem"
                  height="1.2rem"
                  style={{ color: "white" }}
                ></iconify-icon>
              </div>
              <div className="flex flex-auto w-2/3">
                <p className="text-white" style={{ direction: "rtl" }}>
                  امکان استفاده از تمام قسمت های سیستم به صورت نامحدود
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Purchase;
