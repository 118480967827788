import axios from 'axios'
import Cookies from 'js-cookie';

const SERVER_URL = "https://chat.ivaai.ir/";

const token = localStorage.getItem("token");

export const createAdmin = async (phone_number) => {
  const url = `${SERVER_URL}a/`;
  // return axios.post(url, phone_number);
  // console.log("hi")
  const response = await axios.post(url, phone_number);
  // console.log(response)
  return response ;
}



export const verify = async (admin) => {
  const url = `${SERVER_URL}z/`;
  // console.log(admin)
  const response = await axios.post(url, admin);
  // console.log(response.data.token)
  // localStorage.setItem("token",(token))
  return response;
}

export const getAllAdmin = () => {
  const url = `${SERVER_URL}/admin`;
  return axios.get(url);
};


function getCsrfToken() {
  const csrfToken = document.cookie.split('; ')
      .find(row => row.startsWith('csrftoken='))
      ?.split('=')[1];
  return csrfToken;
}

export const LogoutUser = async () => {
  const url = `${SERVER_URL}logout/`;
  const csrfToken = getCsrfToken(); // Get the CSRF token from the cookie
  // console.log(csrfToken)
  return axios.post(url, {}, {
      headers: {
          'X-CSRFToken': csrfToken,
      },
  });
}