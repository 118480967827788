import React, { useState, useEffect } from 'react';
import hljs from 'highlight.js';
import '../css/dracula.css';

const Typewriter = ({ text, delay }) => {
  const [preProcessedText, setPreProcessedText] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const isPersian = (text) => {
      const persianCharRegex = /[\u0600-\u06FF]/;
      return persianCharRegex.test(text);
    };

    const processedText = text.split('```').flatMap((section, i) => {
      if (i % 2 === 0) {
        // Regular text section
        const paragraphs = section.split(/\n\s*\n/).map((paragraph, index) => {
          const direction = isPersian(paragraph) ? 'rtl' : 'ltr';
          const boldedParagraph = paragraph.replace(/\*\*(.*?)\*\*/g, '<b>\$1</b>'); // Apply bold formatting

          return (
            <p className='text-base mx-3' key={`${i}-${index}`} style={{  margin: "10px 10px", lineHeight: "2", direction }} dangerouslySetInnerHTML={{ __html: boldedParagraph }} />

          );
        });
  
        return paragraphs;
      } else {
        // Code snippet section
        return (
          <div key={i} className="code-snippet rounded-md" style={{fontSize:"medium",  margin: "10px 10px", lineHeight: "1.7", backgroundColor: "#2B2B2B" }}>
            <pre>
              <code className="hljs rounded-xl" dangerouslySetInnerHTML={{ __html: hljs.highlightAuto(section).value }} />
            </pre>
          </div>
        );
      }
    });
    setPreProcessedText(processedText);
  }, [text]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTextIndex((prevIndex) => prevIndex + 1);
    }, delay);

    return () => clearInterval(timer);
  }, [delay]);

  return (
    <div >
      {preProcessedText.slice(0, currentTextIndex)}
    </div>
  );
};

export default Typewriter;