import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { AllChatRoomsId, AllchatRooms } from "../services/chatGpt";
import { useRef } from "react";
// import "../css/chat.css"
import ChatRoomComponent from "./ChatroomComponent";
import { ChatContext } from "../context/chatGpt";

export const ChatroomHis = ({
  allChatData,
  onAllChatDataUpdate,
  onClick,
  lastClickedChatRoomId,
}) => {
  const { setChatRooms, chatRooms } = useContext(ChatContext);
  const [clickedChatRoomId, setClickedChatRoomId] = useState(null);

  const clickedChatRoomIdRef = useRef(null);

  const isChatRoomClicked = (chatRoomId) =>
    lastClickedChatRoomId === chatRoomId;

  const onChildClick = async (chatRoomId) => {
    // Update the ref only if chatRoomId is not undefined
    if (chatRoomId !== undefined) {
      clickedChatRoomIdRef.current = chatRoomId;
      handleClickChatRoom(chatRoomId);
      onAllChatDataUpdate(allChatData, chatRoomId);
      onClick(chatRoomId);
    }

  };



  ////this is the working onw
  // useEffect(() => {
  //   const fetchChats = async () => {
  //     try {
  //       const response = await AllchatRooms();

  //       response.forEach((chatroom) => {
  //         // console.log(chatroom);
  //         setChatRooms((prevChatRooms) => ({
  //           ...prevChatRooms,
  //           [chatroom.id]: chatroom,
  //         }));
  //       });

  //       // console.log("lets see : ", chatRooms);
  //     } catch (error) {
  //       console.log("Error fetching chats:", error);
  //     }
  //   };

  //   fetchChats();
  // }, []);

  // useEffect(() => {
  //   const fetchChats = async () => {
  //     try {
  //       const response = await AllchatRooms();

  //       // Sort chatrooms based on 'created_at' timestamp in descending order
  //       response.sort(
  //         (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //       );
  //       // Update the state with sorted chatrooms
  //       const sortedChatRooms = {};
  //       response.forEach((chatroom) => {
  //         sortedChatRooms[chatroom.id] = chatroom;
  //       });

  //       setChatRooms(sortedChatRooms);

  //       // console.log("Sorted chatrooms: ", sortedChatRooms);
  //     } catch (error) {
  //       // console.log("Error fetching chats:", error);
  //     }
  //   };

  //   fetchChats();
  // }, []);

  const fetchChats = async () => {
    try {
      const response = await AllchatRooms();
      const filteredResponse = await Promise.all(response.map(async (chatroom) => {
        const chatRoomMessages = await AllChatRoomsId(chatroom.id);
        if (chatRoomMessages && chatRoomMessages.chats && chatRoomMessages.chats.length > 0) {
          return chatroom;
        }
        return null;
      }));
      const nonEmptyChatRooms = filteredResponse.filter(chatroom => chatroom !== null);
      
      setChatRooms(nonEmptyChatRooms.reduce((acc, chatroom) => {
        acc[chatroom.id] = chatroom;
        return acc;
      }, {}));
    } catch (error) {
      console.log("Error fetching chats:", error);
    }
  };
  
  useEffect(() => {
    fetchChats();
  }, []);

  useEffect(() => {
  }, [chatRooms]);

  const handleClickChatRoom = async (chatRoomId) => {
    try {
      const chatRoomMessages = await AllChatRoomsId(chatRoomId);

      if (
        chatRoomMessages &&
        chatRoomMessages.chats &&
        chatRoomMessages.chats.length > 0
      ) {
        const selectedChatMessages = chatRoomMessages.chats;

        // Store chat messages in a variable before sending them back to the parent
        const newChatMessages = selectedChatMessages.map((chat) => ({
          text_to_send: chat.text_to_send,
          text_to_receive: chat.text_to_receive,
        }));

        // Send the new chat messages back to the parent component
        onAllChatDataUpdate(selectedChatMessages, chatRoomId);
        // console.log(newChatMessages);

        // console.log("Selected chat room messages:", selectedChatMessages);
      } else {
        // console.log("No chats found for the specified chat room");
      }
    } catch (error) {
      // console.error("Error fetching chat room messages:", error);
    }
  };


  return (
    <>
      <div className="w-full h-full overflow-y-auto p-1 " id="style-2">
        {Object.values(chatRooms)
          .reverse()
          .map((chatRoom) => (
            <div
              key={chatRoom.id}
              
              className={`rounded-md mb-2.5  p-1 w-full min-h-8 ${
                clickedChatRoomId === chatRoom.id ? "clicked" : ""
              }`}
              style={{
                backgroundColor:
                  clickedChatRoomId === chatRoom.id ? "#76767A" : "inherit",
              }}
              onClick={() => {
                setClickedChatRoomId(chatRoom.id);
                onChildClick(chatRoom.id);
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#76767A'; // 
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = clickedChatRoomId === chatRoom.id ? "#76767A" : "inherit";
              }}
            >
              <ChatRoomComponent
                chatRoom={chatRoom}
                onChildClick={onChildClick}
                isClicked={isChatRoomClicked(chatRoom.id)}
              />
            </div>
          ))}
      </div>
    </>
  );
};

export default ChatroomHis;
