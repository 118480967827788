import { useContext } from "react";
import Swal from "sweetalert2";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { User, Accounts, Purchase } from "../components";
import { adminContext } from "../context/adminContext";
import { LogoutUser } from "../services/adminServices";
import { SnackbarProvider } from "notistack";

import { toast } from 'react-hot-toast';

import '../css/sidenav.css'

const Settinglg = ({ handleSettinglgClose }) => {
  const { loading, setLoading } = useContext(adminContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [activeComponent, setActiveComponent] = useState(null);

  // Update the active component when clicked
  const handleComponentClick = (component) => {
    setActiveComponent(component);
  };

  const colorLink = (event, targetLink) => {
    const linkColor = document.querySelectorAll(".nav_link");
    if (linkColor) {
      linkColor.forEach((l) => {
        l.classList.remove("active");
        l.style.border = "none"; // Remove border from all links
      });
      targetLink.classList.add("active");
      targetLink.style.border = "1px solid #7289F4"; // Add border to the clicked link
      targetLink.style.color = "white";
    }
  };

  const components = [
    { name: "users", component: <User /> },
    { name: "account", component: <Accounts /> },
    { name: "purchase", component: <Purchase /> },
  ];


  const logOut = () => {
    // Display a SweetAlert confirmation dialog
    Swal.fire({
      title: "آیا تمایل دارید از اکانت خود خارج شوید؟",
      showCancelButton: true,
      confirmButtonColor: "#7289F4",
      cancelButtonColor: "#7289F4",
      confirmButtonText: "بله",
      cancelButtonText: "خیر",
      customClass: {
        container: 'sweet-alert-container'
      }
    }).then((result) => {
      if (result.isConfirmed) {
        LogoutForm();
      }
    });
  };

  const LogoutForm = async () => {
    try {
      setLoading(true);
      setError(null);

      const token = localStorage.getItem("token");
      const { status } = await LogoutUser(token);

      if (status === 200) {
        localStorage.removeItem("token");
        navigate("/");
      } else {
        toast.error(".خطایی رخ داد.دوباره تلاش کنید", {
          style: {
            background: "red",
            color: "white",
          },
        });
      }
    } catch (err) {
      toast.error(".خطایی رخ داد.دوباره تلاش کنید", {
        style: {
          background: "red",
          color: "white",
        },
      });
    } finally {
      setLoading(false);
      toast.error(".خطایی رخ داد.دوباره تلاش کنید", {
        style: {
          background: "red",
          color: "white",
        },
      });
    }
  };

  return (
    <div className="settinglg-container flex flex-row justify-center items-center">
      {/* Your Settinglg component content */}
      <div className="flex">
        <button onClick={handleSettinglgClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>
      </div>
      <SnackbarProvider maxSnack={3} style={{ zIndex: "10" }}>
        <div className="bg-bgDark w-2/3 h-4/5  rounded-lg p-1.5">
          <div className="flex flex-row  h-full  rounded-lg">
            
          
            <div className="w-1/5 justify-center ">
              <div className=" flex flex-col mt-3">
                <a
                  className="nav_link flex flex-col justify-center items-center hover:bg-slate-600 rounded-xl mx-2 p-2 cursor-pointer no-underline"
                  onClick={(e) => {
                    colorLink(e, e.currentTarget);
                    handleComponentClick("account");
                  }}
                >
                  <iconify-icon
                    icon="mingcute:grid-fill"
                    style={{ color: "white" }}
                    width="1.2rem"
                  ></iconify-icon>
                  <span className="text-lg text-white">داشبورد</span>{" "}
                </a>
                <a
                  className="nav_link flex flex-col justify-center items-center hover:bg-slate-600 rounded-xl mx-2 p-2 cursor-pointer no-underline"
                  onClick={(e) => {
                    colorLink(e, e.currentTarget);
                    handleComponentClick("users");
                  }}
                >
                  <iconify-icon
                    icon="mage:user-fill"
                    style={{ color: "white" }}
                    width="1.2rem"
                  ></iconify-icon>
                  <span className="text-lg text-white">کاربران</span>{" "}
                </a>

                <a
                  className="nav_link flex flex-col justify-center items-center hover:bg-slate-600 rounded-xl mx-2 p-2 cursor-pointer no-underline"
                  onClick={(e) => {
                    colorLink(e, e.currentTarget);

                    handleComponentClick("purchase");
                  }}
                >
                  <iconify-icon
                    icon="bxs:purchase-tag-alt"
                    style={{ color: "white" }}
                    width="1.2rem"
                  ></iconify-icon>
                  <span className="text-lg text-white"> خرید اکانت</span>
                </a>

                <a
                  className="nav_link flex flex-col justify-center items-center hover:bg-slate-600 rounded-xl mx-2 p-2 cursor-pointer no-underline"
                  onClick={(e) => {
                    colorLink(e, e.currentTarget);
                    logOut();
                  }}
                >
                  <iconify-icon
                    icon="ri:logout-circle-fill"
                    style={{ color: "white" }}
                    width="1.2rem"
                  ></iconify-icon>{" "}
                  <span className="text-lg text-white">خروج از اکانت</span>{" "}
                </a>
              </div>
            </div>
            <div className="flex-1 overflow-y-auto mt-2">
              {activeComponent ? (
                <>
                  <div className="p-1">
                    {" "}
                    {/* New scrollable container */}
                    {components.map((item, index) => {
                      if (item.name === activeComponent) {
                        return (
                          <React.Fragment key={index}>
                            {item.component}
                          </React.Fragment>
                        );
                      }
                      return null;
                    })}
                  </div>
                </>
              ) : (
                <div className="p-1">
                  <Accounts />{" "}
                </div>
              )}
            </div>
          </div>
        </div>
      </SnackbarProvider>

    </div>
  );
};
export default Settinglg;
