const Charge = () => {
  const styles = {
    container: {
      direction: "rtl",
    },
  };
  return (
    // <div style={styles.container} className="pt-5">
    //   <div className="row border rounded-3 p-2 align-items-center mb-3 mt-3 ms-2">
    //     <div className="col">
    //       <div class="card-body text-white">- شارژ اکانت به اندازه 10000 تومان</div>
    //     </div>{" "}
    //     <div className="col-1 float-left">
    //       <button className="btn btn-primary">پرداخت</button>
    //     </div>
    //   </div>


    // </div>
    <>
    </>
  );
};

export default Charge;
