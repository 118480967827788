import { createContext } from 'react'
export const UserContext = createContext({
  user: {},
  allUsers: {},
  compUsers: {},
  setAllUsers: () => { },
  setUser: () => { },
  Loginuser: {},
  setLoginUser: () => { },
  checkLogin: {},
  setCompUsers: () => { },
  onUserChange: () => { },
  createUserForm: () => { },
  forgetpass: () => { },
  LoginForm: () => { },
  forgetSuccess: {},
  setForgetSuccess: () => { },
  verifyOtp: {},
  setVerifyOtp: () => { },
  currentphone: {},
  setCurrentphone: () => { },
  VerifyNewOtp: () => { },
  newOtp: {},
  setNewOtp: () => { },
  NewpassConfirm: () => { },
  newpassword: {},
  setNewpassword: () => { },
  confirmPass: {},
  setconfirmPass: () => { },
  handleFormChange: () => { },
  GetUserInfo: () => { },
  IsPayde: {},
  setIsPayed: () => { },

})