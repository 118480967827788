import { useContext } from "react";
import React from "react";
import { UserContext } from "../context/userContext";
import { CompanyContext } from "../context/companyContext";

const Users = ({ hideNoUsers }) => {
  const { compUsers } = useContext(UserContext);
  const { company } = useContext(CompanyContext);
  console.log(company.Number_user)
  return (
    <>
        <div className="flex flex-col w-full text-start gap-3 mx-1">
          {compUsers.length > 0 ? (
            compUsers.map((user) => (
              <div
                className="flex flex-col w-full  border rounded-lg"
                key={user.id}
              >
                <span className="text-white text-sm overflow-hidden pl-4 capitalize mt-2" >{user.full_name}</span>
                <span className="text-gray-500 m-1 text-md overflow-hidden pl-3 capitalize" >{user.email}</span>
              </div>
            ))
          ) : (
            <p className="text-center "
            style={{ display: hideNoUsers ? "none" : "flex"  ,  fontSize: "medium" , color:"white" }}

            >
              !شما کاربری ندارید{" "}
            </p>
          )}
        </div>
    </>
  );
};

export default Users;
