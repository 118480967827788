// signup and signin page

import { useContext, useState, useEffect } from "react";

import * as React from "react";

// import "../css/newsignup.css";
import "../css/newcss.css";

import { adminContext } from "../context/adminContext";
import { CompanyContext } from "../context/companyContext";
import { signupformContext } from "../context/signupformContext";
import { UserContext } from "../context/userContext";

import flying from "../assets/flying.gif";
import bg from "../assets/5.jpg";

const Trying = ({ passwordError, NewPhone, ExistName, ExistEmial }) => {
  const { admin, onAdminChange, createAdminForm, verifyForm, passwordColor } =
    useContext(adminContext);

  const { company, onCompanyChange, createCompanyForm } =
    useContext(CompanyContext);
  const { triggerSignup } = useContext(signupformContext);
  const {
    Loginuser,
    setLoginUser,
    forgetpass,
    currentphone,
    setCurrentphone,
    newOtp,
    setNewOtp,
    VerifyNewOtp,
    newpassword,
    confirmPass,
    NewpassConfirm,
    handleFormChange,
    LoginForm,
    forgetSuccess,
    verifyOtp,
  } = useContext(UserContext);

  const [phoneVerificationFailed, setPhoneVerificationFailed] = useState(false);

  //localstorage
  const phoneNumber = JSON.parse(localStorage.getItem("phone_number"));
  const FullName = JSON.parse(localStorage.getItem("full_name"));

  //forgot pass
  const current = JSON.parse(localStorage.getItem("currentphone"));

  // showpasss
  const [showPassword, setShowPassword] = React.useState(false);

  // for largescreens signup

  const [activeForm, setActiveForm] = useState("login");

  const handleFormChange2 = (form) => {
    setActiveForm(form);
  };

  useEffect(() => {
    if (phoneVerificationFailed) {
      setPhoneVerificationFailed(false); // Reset the phone verification failed state after showing the alert
    }
  }, [phoneVerificationFailed]);

  const [showLoginForm, setShowLoginForm] = useState(true); // Initially show the login form
  const [activeTab, setActiveTab] = useState("login");

  const handleSignUp = () => {
    setShowLoginForm(false);
    setActiveTab("signup");
  };

  const handleLogin = () => {
    setShowLoginForm(true);
    setActiveTab("login");
  };

  // retreived
  const handleSignUpClick = () => {
    const container = document.querySelector(".bg");
    container.classList.add("right-panel-active");
  };

  const handleSignInClick = () => {
    const container = document.querySelector(".bg");
    container.classList.remove("right-panel-active");
  };

  const handleBackClick = () => {
    const container = document.querySelector(".bg");
    container.classList.remove("right-panel-active2");
  };

  const handleVerify = () => {
    if (NewPhone == true) {
      const container = document.querySelector(".bg");
      container.classList.add("right-panel-active2");
    } else {
    }
  };

  const handleCompany = () => {
    // console.log(ExistName, ExistEmial);
    if (ExistName == true && ExistEmial == true) {
    } else {
      const container = document.querySelector(".bg");
      container.classList.add("right-panel-active3");
    }
  };

  const handleBackClick2 = () => {
    const container = document.querySelector(".bg");
    container.classList.remove("right-panel-active3");
  };
  const handleForgetPass = () => {
    const container = document.querySelector(".bg");
    container.classList.add("right-panel-active4");
  };
  const handleBackClickLogin = () => {
    const container = document.querySelector(".bg");
    container.classList.remove("right-panel-active4");
  };

  const handleVerifyPass = () => {
    const container = document.querySelector(".bg");
    container.classList.add("right-panel-active5");
  };

  const handleBackClickLogin1 = () => {
    const container = document.querySelector(".bg");
    container.classList.remove("right-panel-active5");
  };

  const handleNewPass = () => {
    const container = document.querySelector(".bg");
    container.classList.add("right-panel-active6");
  };

  return (
    <>
      <div className="flex flex-row w-screen h-screen items-center justify-center mx-auto ">
        <div
          className="flex flex-col h-full md:max-h-96 p-4  max-w-5xl w-full md:w-0  md:rounded-3xl 
              md:shadow-lg   md:shadow-slate-600 "
          style={{ backgroundImage: `url(${bg})` }}
        >
          <ul class="flex flex-row   justify-center items-center md:items-center list-none mb-8 text-lg font-bold text-gray-500 cursor-pointer sm:hidden">
            <li
              className={`cursor-pointer ${
                activeTab === "login"
                  ? "px-4 mx-2 gap-2 border-b-2 border-blue-500"
                  : "mx-2 text-slate-400"
              }`}
              onClick={handleLogin}
            >
              log in
            </li>
            <li
              className={`cursor-pointer ${
                activeTab === "signup"
                  ? "px-4 mx-2 border-b-2 border-blue-500"
                  : "mx-2 text-slate-400"
              }`}
              onClick={handleSignUp}
            >
              signUp
            </li>
          </ul>
          {/* <div className="fixed z-2  right-0 bottom-0 sm:hidden">
            <img src={flying} className="h-60 w-full sm:h-48" />
          </div> */}
          <div className="md:hidden h-screen">
            {showLoginForm ? (
              <>
                {/*  form login*/}
                {activeForm === "login" && (
                  <div className="loginForm">
                    <form
                      className="flex flex-col w-full justify-end right-0 bg-transparent "
                      onSubmit={LoginForm}
                    >
                      <div className="flex flex-col items-center w-full ">
                        <div className="relative w-full m-3">
                          <input
                            type="text"
                            className="h-10 w-full rounded-full pl-10 focus:outline-none bg-gray-300  "
                            placeholder="شماره خود را وارد کنید"
                            name="phone_number"
                            value={Loginuser.phone}
                            onChange={(e) =>
                              setLoginUser((prevUser) => ({
                                ...prevUser,
                                phone: e.target.value,
                              }))
                            }
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4 absolute top-3 left-3"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="flex flex-col items-center w-full text-right">
                        <div className="relative w-full">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="h-10 w-full rounded-full pl-10 focus:outline-none bg-gray-300 shadow-glass "
                            placeholder="رمز عبور"
                            name="password"
                            value={Loginuser.password}
                            onChange={(e) =>
                              setLoginUser((prevUser) => ({
                                ...prevUser,
                                password: e.target.value,
                              }))
                            }
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            className="w-4 h-4 absolute top-3 left-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="flex flex-col items-center w-full mt-3">
                        <a
                          className="text-slate-200 cursor-pointer"
                          onClick={() => {
                            handleFormChange2("forget");
                          }}
                        >
                          فراموشی رمز عبور؟
                        </a>
                      </div>

                      <button
                        type="submit"
                        className="rounded-lg btn-continue  w-full h-12  mb-3 z-2"
                      >
                        <span className="btn-text text-white text-xl">
                          {" "}
                          ورود
                        </span>{" "}
                      </button>
                    </form>
                  </div>
                )}

                {activeForm === "forget" && (
                  <div
                    className={`forgetForm ${
                      forgetSuccess ? "opacity-0" : "opacity-100"
                    }`}
                    hidden={activeForm !== "forget"}
                  >
                    <form
                      onSubmit={forgetpass}
                      className="flex flex-col w-full gap-2 justify-center right-0 bg-transparent"
                    >
                      <p className="text-xl font-bold text-center text-slate-200">
                        شماره موبایل خودرا وارد کنید
                      </p>
                      <div className="relative w-full my-3">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="تلفن همراه "
                          required={true}
                          value={currentphone}
                          onChange={(e) => setCurrentphone(e.target.value)}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>
                      </div>
                      <button
                        type="submit"
                        className="rounded-lg btn-continue  w-full h-12  mb-3 z-2"
                      >
                        <span className="btn-text text-xl">
                          {" "}
                          دریافت کد تایید
                        </span>
                      </button>
                    </form>
                  </div>
                )}

                {forgetSuccess ? (
                  <div
                    className={`otp ${
                      verifyOtp
                        ? "opacity-0"
                        : "top-0  absolute inset-0 flex z-2 "
                    } `}
                  >
                    <form
                      className="flex flex-col w-full gap-2 mt-5 top-10  right-0  bg-transparent mx-4 "
                      onSubmit={VerifyNewOtp}
                    >
                      <div className="relative w-full mt-5 mb-3">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="تلفن همراه "
                          name="phone_number"
                          value={current}
                          disabled
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full mb-2">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="کد ارسال شده را وارد کنید"
                          required={true}
                          name="otp_code"
                          value={newOtp.otp_code}
                          onChange={(e) => {
                            setNewOtp(e.target.value);
                          }}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                          />
                        </svg>
                      </div>
                      <button
                        onClick={() => handleFormChange2("pass")}
                        type="submit"
                        className="rounded-full btn-continue mt-2 w-full"
                      >
                        <span class="btn-text text-xl"> تایید</span>
                      </button>
                    </form>
                  </div>
                ) : (
                  <></>
                )}

                {verifyOtp ? (
                  <div className="pass top-10  absolute inset-0 flex z-2 ">
                    <form
                      className="flex flex-col w-full gap-2 mt-5 top-10  right-0 bg-transparent mx-4"
                      onSubmit={NewpassConfirm}
                    >
                      <div className="relative w-full mt-4 mb-2">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="تلفن همراه "
                          name="phone_number"
                          disabled
                          value={current}
                          onChange={handleFormChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full mb-2">
                        <input
                          style={{ color: passwordColor, fontWeight: "bold" }}
                          type="password"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="رمز ورود جدید"
                          name="new_password"
                          required={true}
                          value={admin.new_password}
                          onChange={handleFormChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-2 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          style={{ color: passwordColor, fontWeight: "bold" }}
                          type="password"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="تکرار رمز ورود جدید "
                          name="confirm_password"
                          required={true}
                          value={admin.confirm_password}
                          onChange={handleFormChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-2 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      </div>
                      <button
                        type="submit"
                        className="rounded-full btn-continue mt-2 w-full"
                      >
                        <span class="btn-text text-xl"> تایید</span>
                      </button>
                    </form>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <div className="z-1 overflow-hidden h-full ">
                {/* signup form  */}
                <div
                  className={`signupForm ${
                    NewPhone ? "opacity-0 " : "items-center align-middle p-1"
                  } `}
                >
                  <form
                    className="flex flex-col w-full justify-end right-0 bg-transparent "
                    onSubmit={createAdminForm}
                  >
                    <div className="flex flex-col items-center w-full">
                      <span className="text-slate-200 text-2xl  font-bold m-2">
                        ساخت حساب کاربری
                      </span>

                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-10 w-full rounded-full  my-5 focus:outline-none bg-gray-300"
                          placeholder="تلفن همراه "
                          name="phone_number"
                          value={admin.phone_number}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-14 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>
                      </div>

                      <button
                        type="submit"
                        className="rounded-lg btn-continue  w-full h-12  mb-3 z-2"
                      >
                        <span className=" text-xl"> ادامه</span>{" "}
                      </button>
                    </div>
                  </form>
                </div>
                {NewPhone ? (
                  <div
                    className={`verifyForm ${
                      ExistName
                        ? "opacity-0 "
                        : " absolute inset-0 mx-4 top-10 z-2"
                    } `}
                  >
                    <form
                      className="flex flex-col w-full gap-2 mt-5 justify-center right-0 bg-transparent"
                      onSubmit={verifyForm}
                    >
                      <span className="text-slate-200 mb-2 text-2xl text-center">
                        لطفا اطلاعات خودرا کامل کنید.
                      </span>
                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="کد ارسال شده را وارد کنید"
                          required={true}
                          name="otp_code"
                          value={admin.otp_code}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="تلفن همراه "
                          disabled
                          name="phone_number"
                          value={phoneNumber}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="ایمیل"
                          required={true}
                          name="email"
                          value={admin.email}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-6 absolute top-1 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="  نام و نام خانوادگی"
                          required={true}
                          name="full_name"
                          value={admin.full_name}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-2 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          style={{ color: passwordColor, fontWeight: "bold" }}
                          type="password"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="رمز ورود"
                          name="password"
                          required={true}
                          value={admin.password}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-3 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="password"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                          placeholder="تکرار رمز ورود "
                          name="confirmpassword"
                          required={true}
                          value={admin.confirmpassword}
                          onChange={onAdminChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-3 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                          />
                        </svg>
                      </div>

                      {passwordError && (
                        <h8 className="text-danger">{passwordError}</h8>
                      )}
                      <button
                        onClick={handleCompany}
                        type="submit"
                        className="rounded-full btn-continue mt-2 w-full"
                      >
                        <span className="text-xl"> تایید</span>{" "}
                      </button>
                    </form>
                  </div>
                ) : (
                  <></>
                )}
                {ExistName ? (
                  <div className="companyForm  absolute inset-0 mx-4 top-10 z-2">
                    <form
                      className="flex flex-col w-full mt-5 justify-end right-0 bg-transparent "
                      onSubmit={createCompanyForm}
                    >
                      <select
                        className="form-select px-4 rounded-full text-center my-2"
                        name="Number_user"
                        onChange={onCompanyChange}
                      >
                        <option selected value="">
                          تعداد کاربران خود را مشخص کنید
                        </option>
                        <option value="1">تک کاربر</option>
                        <option value="3">سه کاربر</option>
                        <option value="5">پنج کاربر</option>
                        <option value="7">هفت کاربر</option>
                        <option value="10">ده کاربر</option>
                        <option value="15">پانزده کاربر</option>
                        <option value="20">بیست کاربر</option>
                      </select>

                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300 my-3"
                          placeholder="نام شرکت یا نام مستعار خود را وارد کنید"
                          name="full_name"
                          // value={FullName}
                          onChange={onCompanyChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-6 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>
                      </div>
                      <div className="relative w-full">
                        <input
                          type="text"
                          className="h-8 w-full rounded-full focus:outline-none bg-gray-300 my-3"
                          placeholder="   آدرس"
                          name="address"
                          required={true}
                          value={company.address}
                          onChange={onCompanyChange}
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 absolute top-6 left-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                          />
                        </svg>
                      </div>
                      <button
                        type="submit"
                        className="rounded-full btn-continue mt-2 w-full"
                      >
                        <span className="text-xl"> تایید</span>{" "}
                      </button>
                    </form>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>

          {/* largescreens */}
          <div className="hidden md:block">
            <div className="  bg container max-h-96 mx-auto inset-0 w-screen bg-transparent ">
              <div className=" absolute top-0 h-full sign-up-container mb-2 ">
                <form onSubmit={createAdminForm} className="phoneForm ">
                  <span className=" text-xl text-white m-3">
                    ساخت حساب کاربری
                  </span>
                  <input
                    className="rounded-3xl"
                    type="text"
                    placeholder="تلفن همراه "
                    required={true}
                    name="phone_number"
                    value={admin.phone_number}
                    onChange={onAdminChange}
                  />
                  <button
                    onClick={handleVerify}
                    type="submit"
                    className="rounded-lg btn-continue mt-3 w-1/2 p-3"
                  >
                    <span className="btn-text w-full text-xl"> ادامه</span>{" "}
                  </button>
                </form>
              </div>

              <div className="form-container verify-container">
                <form onSubmit={verifyForm}>
                  <span
                    className="absolute left-3 bg-transparent"
                    onClick={handleBackClick}
                  >
                    <iconify-icon
                      icon="fe:arrow-left"
                      width="24"
                      height="24"
                    ></iconify-icon>
                  </span>
                  <span className="text-gray-200 mb-2 text-2xl text-center">
                    لطفا اطلاعات خودرا کامل کنید.
                  </span>

                  <div className="relative w-full mb-1.5">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="کد ارسال شده را وارد کنید"
                      required={true}
                      name="otp_code"
                      value={admin.otp_code}
                      onChange={onAdminChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-6 absolute top-1 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                      />
                    </svg>
                  </div>
                  <div className="relative w-full mb-1.5">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="تلفن همراه "
                      disabled
                      name="phone_number"
                      value={phoneNumber}
                      onChange={onAdminChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-6 absolute top-1 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </div>
                  <div className="relative w-full mb-1.5">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="ایمیل"
                      required={true}
                      name="email"
                      value={admin.email}
                      onChange={onAdminChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-6 absolute top-1 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                  <div className="relative w-full mb-1.5">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="  نام و نام خانوادگی"
                      required={true}
                      name="full_name"
                      value={admin.full_name}
                      onChange={onAdminChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-2 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  </div>
                  <div
                    className="relative w-full mb-1.5"
                    style={{ color: passwordColor, fontWeight: "bold" }}
                  >
                    <input
                      type="password"
                      autoComplete=""
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="رمز ورود"
                      name="password"
                      required={true}
                      value={admin.password}
                      onChange={onAdminChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-2 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                  </div>
                  <div className="relative w-full mb-1.5">
                    <input
                      type="password"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="تکرار رمز ورود "
                      name="confirmpassword"
                      required={true}
                      autoComplete=""
                      value={admin.confirmpassword}
                      onChange={onAdminChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-2 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                  </div>

                  {passwordError && (
                    <h8 className="text-danger">{passwordError}</h8>
                  )}

                  <button
                    onClick={handleCompany}
                    type="submit"
                    className="rounded-full btn-continue mt-2 w-full h-12"
                  >
                    <span className="btn-text text-xl"> تایید</span>{" "}
                  </button>
                </form>
              </div>

              {/* company form */}
              <div className="form-container company-container ">
                <form onSubmit={createCompanyForm}>
                  <div className="absolute left-3" onClick={handleBackClick2}>
                    <iconify-icon
                      icon="fe:arrow-left"
                      width="24"
                      height="24"
                    ></iconify-icon>
                  </div>

                  <select
                    class="form-select px-4 rounded-full text-center "
                    name="Number_user"
                    onChange={onCompanyChange}
                  >
                    <option selected value="">
                      تعداد کاربران خود را مشخص کنید
                    </option>
                    <option value="1">تک کاربر</option>
                    <option value="3">سه کاربر</option>
                    <option value="5">پنج کاربر</option>
                    <option value="7">هفت کاربر</option>
                    <option value="10">ده کاربر</option>
                    <option value="15">پانزده کاربر</option>
                    <option value="20">بیست کاربر</option>
                  </select>

                  <div className="relative w-full">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300 my-3"
                      placeholder="نام شرکت یا نام مستعار خود را وارد کنید"
                      name="full_name"
                      // value={FullName}
                      onChange={onCompanyChange}
                    />

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-6 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  </div>
                  <div className="relative w-full">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300 my-3"
                      placeholder="   آدرس"
                      name="address"
                      required={true}
                      value={company.address}
                      onChange={onCompanyChange}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-6 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>
                  </div>
                  <button
                    className="rounded-lg btn-continue  w-full h-12 mt-5 mb-3"
                    type="submit"
                  >
                    <span className="btn-container"></span>
                    <span className="btn-text text-center text-xl">
                      {" "}
                      ثبت نام
                    </span>
                  </button>
                </form>
              </div>
              {/* login */}
              <div className="form-container sign-in-container w-full">
                <form onSubmit={LoginForm}>
                  <p className="text-white text-3xl font-bold">ورود</p>
                  <div className="relative w-full m-3">
                    <input
                      type="text"
                      className="h-10 w-full rounded-full pl-10 focus:outline-none bg-gray-300  "
                      placeholder="شماره خود را وارد کنید"
                      name="phone_number"
                      value={Loginuser.phone}
                      onChange={(e) =>
                        setLoginUser((prevUser) => ({
                          ...prevUser,
                          phone: e.target.value,
                        }))
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-3 left-3"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                      />
                    </svg>
                  </div>
                  <div className="relative w-full">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="h-10 w-full rounded-full pl-10 focus:outline-none bg-gray-300 shadow-glass "
                      placeholder="رمز عبور"
                      name="password"
                      value={Loginuser.password}
                      onChange={(e) =>
                        setLoginUser((prevUser) => ({
                          ...prevUser,
                          password: e.target.value,
                        }))
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-4 absolute top-3 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                      />
                    </svg>
                  </div>

                  <div className="flex flex-col items-center w-full mt-3">
                    <a
                      className="text-slate-200 text-lg cursor-pointer"
                      onClick={handleForgetPass}
                    >
                      فراموشی رمز عبور؟
                    </a>
                  </div>
                  <button
                    type="submit"
                    className="rounded-lg btn-continue mt-3 w-1/2 p-3"
                  >
                    <span className="btn-text text-xl"> ورود</span>
                  </button>
                </form>
              </div>
              <div className="form-container forget-pass">
                <form onSubmit={forgetpass}>
                  <div
                    className="left-4 absolute"
                    onClick={handleBackClickLogin}
                  >
                    <iconify-icon
                      icon="fe:arrow-left"
                      width="24"
                      height="24"
                    ></iconify-icon>
                  </div>
                  <h6 className="text-2xl text-white mb-5">
                    لطفا شماره موبایل خودرا وارد کنید.
                  </h6>
                  <div className="relative w-full mb-4">
                    <input
                      type="text"
                      className="h-8 w-full rounded-full focus:outline-none bg-gray-300"
                      placeholder="تلفن همراه "
                      required={true}
                      value={currentphone}
                      onChange={(e) => setCurrentphone(e.target.value)}
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-4 h-6 absolute top-1 left-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                      />
                    </svg>
                  </div>
                  <button
                    onClick={handleVerifyPass}
                    type="submit"
                    className="rounded-full btn-continue mt-2 w-1/2 h-12"
                  >
                    <span className="btn-text text-xl"> دریافت کد تایید</span>
                  </button>
                </form>
              </div>
              {forgetSuccess ? (
                <div className="form-container verify-opt ">
                  <form onSubmit={VerifyNewOtp}>
                    <div
                      style={{
                        position: "absolute",
                        top: "45%",
                        left: "10px",
                        background: "transparent",
                      }}
                      onClick={handleBackClickLogin1}
                    >
                      <iconify-icon
                        icon="fe:arrow-left"
                        width="24"
                        height="24"
                      ></iconify-icon>
                    </div>
                    <div className="relative w-full mb-1.5 mt-3">
                      <input
                        type="text"
                        placeholder="شماره موبایل"
                        className="mb-3 rounded-3"
                        required={true}
                        value={current}
                        disabled
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-6 absolute top-2 left-3"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                    </div>
                    <div className="relative w-full mb-1.5">
                      <input
                        type="text"
                        placeholder="کد تایید را وارد کنید. "
                        className="mb-3 rounded-3"
                        required={true}
                        name="otp_code"
                        value={newOtp.otp_code}
                        onChange={(e) => {
                          setNewOtp(e.target.value);
                        }}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-6 absolute top-2 left-3"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                        />
                      </svg>
                    </div>

                    <button
                      onClick={handleNewPass}
                      type="submit"
                      className="rounded-3 btn-verify btn-login w-50 p-3 m-2 "
                    >
                      <span className="btn-text text-xl"> تایید</span>
                    </button>
                  </form>
                </div>
              ) : (
                <></>
              )}

              {verifyOtp ? (
                <div className="form-container new-pass ">
                  <form onSubmit={NewpassConfirm}>
                    <div className="relative w-full mb-1.5">
                      <input
                        type="text"
                        placeholder="شماره موبایل"
                        className="mb-2 rounded-3 mt-1"
                        required={true}
                        value={current}
                        disabled
                        onChange={handleFormChange}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-6 absolute top-1 left-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>
                    </div>
                    <div
                      className="relative w-full mb-1.5"
                      style={{ color: passwordColor, fontWeight: "bold" }}
                    >
                      <input
                        style={{ color: passwordColor }}
                        type="password"
                        placeholder="رمز ورود جدید را وارد کنید."
                        className="mb-2 rounded-3"
                        name="new_password"
                        required={true}
                        onChange={handleFormChange}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 absolute top-2 left-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                        />
                      </svg>
                    </div>
                    <div className="relative w-full mb-1.5">
                      <input
                        style={{ color: passwordColor }}
                        type="password"
                        placeholder="تکرار رمز "
                        className="mb-2 rounded-3"
                        required={true}
                        name="confirm_password"
                        onChange={handleFormChange}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-4 h-4 absolute top-2 left-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                        />
                      </svg>
                    </div>

                    <button
                      className="rounded-3 btn-verify mt-2 btn-verify"
                      type="submit"
                    >
                      <span className="btn-container"></span>
                      <span className="btn-text text-xl"> تایید</span>
                    </button>
                  </form>
                </div>
              ) : (
                <></>
              )}

              <div className="overlay-container">
                <div className="overlay">
                  <div className="overlay-panel overlay-left">
                    <h1>!خوش آمدید </h1>
                    <p className="mt-2">
                      .برای اینکه با ما در ارتباط بمانید لطفا اطلاعات خود را
                      وارد نمایید
                    </p>

                    <button
                      className="ghost rounded-md btn-login mt-2 border"
                      id="signIn"
                      onClick={handleSignInClick}
                    >
                      <span className="btn-container"></span>
                      <span className="btn-text text-white text-xl"> ورود</span>
                    </button>
                  </div>

                  <div className="overlay-panel overlay-right">
                    <h1 className="mb-3 font-bold"> !سلام</h1>
                    <p className="text-white ">
                      . اطلاعات خود را وارد کنید و سفری را با ما آغاز کنید{" "}
                    </p>

                    <button
                      className="ghost rounded-lg btn-signup mt-3"
                      id="signUp"
                      onClick={handleSignUpClick}
                    >
                      <span className="btn-container"></span>
                      <span className="btn-text text-white text-lg">
                        {" "}
                        ثبت نام
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Trying;
